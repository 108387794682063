import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "home p-8 h-screen overflow-y-auto" }
const _hoisted_2 = { class: "grid grid-cols-3 gap-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_chart = _resolveComponent("v-chart")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.graphs, (option, i) => {
        return (_openBlock(), _createElementBlock("div", {
          key: i,
          class: "chart aspect-square bg-gray-100 rounded-lg"
        }, [
          _createVNode(_component_v_chart, {
            option: option,
            autoresize: ""
          }, null, 8, ["option"])
        ]))
      }), 128))
    ])
  ]))
}