export default class Utils {
  static getObjectKeys<T>(object: T): Array<string> {
    return Object.keys(object);
  }

  // expires in epoch time (unix timestamp)
  static setCookie(name: string, value: string, expires: number) {
    const d = new Date();
    d.setTime(d.getTime() + expires);

    const expiresString: string = "expires=" + d.toUTCString();
    document.cookie = name + "=" + value + ";" + expiresString + ";path=/";
  }

  static getCookie(cname: string) {
    const name = cname + "=";
    const decodedCookie = decodeURIComponent(document.cookie);
    const ca = decodedCookie.split(";");
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == " ") {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }

  static parseJwt(token: string) {
    if (!token) return;
    const base64Url = token.split(".")[1];
    const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );

    return JSON.parse(jsonPayload);
  }

  static pad(num: number, size: number): string {
    let numString = num.toString();
    while (numString.length < size) numString = "0" + numString;
    return numString;
  }
}

export interface UploadProgress {
  progress: number;
}
