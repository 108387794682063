// modules & views
import AuthModule from '@/layouts/AuthModule.vue'
import RegisterForm from '@/partials/auth/RegisterForm.vue';
import LoginForm from '@/partials/auth/LoginForm.vue';
import LoginConfirmForm from '@/partials/auth/LoginConfirmForm.vue';
import ResetPassword from '@/partials/auth/ResetPassword.vue';
import RemindPassword from '@/partials/auth/RemindPassword.vue';
import ActivateAccount from '@/partials/auth/ActivateAccount.vue';
import rootStore from "@/store/rootStore";
import { NavigationGuardNext, RouteLocationNormalized, RouteRecordRaw } from 'vue-router';
import { ExtendedStoreOptions } from '@/store/types';

export const authRouting: RouteRecordRaw[] = [
  {
    path: '/auth',
    name: 'auth',
    component: AuthModule,
    redirect: {
      name: 'login',
    },
    children: [
      {
        path: 'register',
        name: 'register',
        component: RegisterForm,
      },
      {
        path: 'login',
        name: 'login',
        component: LoginForm,
      },
      {
        path: 'login/confirm/:token',
        name: 'confirm-login',
        component: LoginConfirmForm,
        beforeEnter: (to, from, next) => {
          if (to?.params?.token) {
            next()
          } else {
            next('/auth/login')
          }
        }
      },
      {
        path: 'demo',
        name: 'demo',
        redirect: null,
      },
      {
        path: 'remind-password',
        name: 'remind-password',
        component: RemindPassword,
      },
      {
        path: 'reset-password',
        name: 'reset-password',
        redirect: { name:'remind-password' },
      },
      {
        path: 'reset-password/:id',
        name: 'reset-password-by-id',
        component: ResetPassword,
      },
      {
        path: 'impersonate',
        name: 'impersonate',
        redirect: null
      },
      {
        name: 'activate-from-email',
        path: 'activate-from-email/:id',
        component: ActivateAccount
      },
      {
        name: 'activate',
        path: 'activate/:id',
        redirect: null,
        beforeEnter: async (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) => {
          try {
            await rootStore.dispatch('auth/activateAccount', { token: to.params.id });
            setTimeout( () => {
              (this as ExtendedStoreOptions).$notify({
                type: 'success',
                title: 'Success!',
                text: 'Your account has been activated'
              });
            }, 1000)
          } catch (error) {
            setTimeout( () => {
              (this as ExtendedStoreOptions).$notify({
                type: 'error',
                title: 'Error!',
                text: (error as any)?.response.data.label
              });
            }, 1000)
          }
          next('/auth/login');
        }
      }
    ]
  },
  {
    path: '/',
    component: AuthModule,
    children: [
      { path: '/', redirect: '/auth/login' },
      {
        path: 'regulations',
        name: 'regulations',
        redirect: null,
      },
    ]
  }
];
