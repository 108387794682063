<template>
  <div :id="testId" :class="['gn-input', hasError ? 'error' : '']">
    <label v-if="label" :for="id" class="gn-input__label label">{{ label }}</label>
    <div :class="['relative rounded-md shadow-sm', label ? 'mt-2' : '']">
      <div class="gn-input__icon absolute inset-y-0 left-0 flex items-center pl-3">
        <component class="w-5 h-5 text-gray-600" :is="icon"></component>
      </div>
      <input 
        v-bind="{...inputAttrs}"
        :class="['gn-input__field', icon ? 'has-icon' : '']"
        :name="id"
        :id="id"
        :modelValue="value" 
        :aria-invalid="hasError ? 'true' : 'false'" 
        :aria-describedby="`${id}-error`"
        @input="handleInput($event, $event.target.value)"
        @blur="onBlur"
      >
      <div v-if="hasError" class="gn-input__error-icon">
        <svg class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
          <path fill-rule="evenodd"
            d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-5a.75.75 0 01.75.75v4.5a.75.75 0 01-1.5 0v-4.5A.75.75 0 0110 5zm0 10a1 1 0 100-2 1 1 0 000 2z"
            clip-rule="evenodd" />
        </svg>
      </div>
    </div>
    <GenproxError :validation="validation" :id="`${id}-error`" />
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop, Emit, Watch, toNative } from 'vue-facing-decorator'
import GenproxError from '@/components/ui/GenproxError.vue'

@Component({
  components: { GenproxError },
  emits: ['update:modelValue']
})
export class GenproxInput extends Vue {
  @Prop({ default: null }) inputAttrs: Record<string, unknown>
  @Prop({ default: null }) validation: Record<string, unknown>
  @Prop({ default: null }) value!: string | number
  @Prop({ default: null }) modelValue: string
  @Prop({ default: null }) icon: any | Vue
  @Prop({ default: null }) testId: string
  @Prop({ default: null }) label: string
  @Prop({ default: null }) id: string

  @Watch('modelValue', { immediate: true }) onValueChange(): void {
    if (!this.modelValue) {
      this.displayValue = null
      return
    }

    if (this.value !== this.modelValue) {
      this.displayValue = String(this.modelValue)
    }
  }

  displayValue: string = ''

  /**
   * Has error computed property
   * @public
   */
  get hasError(): boolean {
    return !!(this.validation?.$error)
  }

  onBlur(e: any) {
    (this.validation as any)?.$touch()
    e
  }

  /**
   * 
   * @param event 
   * @param value 
   */
  @Emit('update:modelValue')
  handleInput(event: any, value: string | number): string | number {
    const selectionStart = event ? event?.target.selectionStart : false
    const selectionEnd = event ? event?.target.selectionEnd : false

    selectionStart; selectionEnd;

    this.displayValue = String(value)
    
    return value
  }
}

export default toNative(GenproxInput)
</script>

<style lang="postcss" scoped>
.gn-input {
  .gn-input__error-icon {
    @apply pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3 text-red-600;
  }

  .gn-input__field {
    @apply block w-full rounded-md border-0 py-2 px-3 pr-10 outline-none ring-gray-300 ring-1 ring-inset focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6;

    &:focus {
      @apply ring-gray-800;
    }

    &.has-icon {
      @apply pl-10;
    }
  }

  &.error {
    .gn-input__field {
      @apply text-red-700 ring-red-600;
    }
  }
}
</style>

<docs>
```html
<GenproxInput />
```
</docs>