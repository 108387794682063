// formkit.config.ts
import * as icons from '@formkit/icons'
import { defaultConfig } from '@formkit/vue'
import { rootClasses } from './formkit.theme'

export default defaultConfig({
  icons: {
    ...icons,
  },
  config: {
    rootClasses,
  },
})