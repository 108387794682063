import { RouteRecordRaw, createRouter, createWebHistory } from 'vue-router'

// routing
import { authRouting } from './authRouting'

// modules & views
import GenproxModule from '@/layouts/GenproxModule.vue'
import Home from '@/views/Home.vue'

// others
import WykresyPaulina from '@/views/wykresy/WykresyPaulina.vue'
import WykresyMilena from '@/views/wykresy/WykresyMilena.vue'
import WykresyDarek from '@/views/wykresy/WykresyDarek.vue'
import DarekPlatform from '@/views/DarekPlatform.vue'
import Paulina from '@/views/Paulina.vue'
import Milena from '@/views/Milena.vue'
import Darek from '@/views/Darek.vue'

const routes: RouteRecordRaw[] = [
  ...authRouting,
  {
    path: '/',
    component: GenproxModule,
    children: [
      { path: '/', redirect: 'welcome' },
      {
        path: '/welcome',
        name: 'welcome',
        component: Home
      },
      {
        path: '/darek-platform',
        name: 'darek-platform',
        component: DarekPlatform
      },
    ],
  },
  {
    path: '/paulina',
    name: 'paulina',
    component: Paulina,
  },
  {
    path: '/milena',
    name: 'milena',
    component: Milena,
  },
  {
    path: '/darek',
    name: 'darek',
    component: Darek,
  },
  {
    path: '/wykresy-paulina',
    name: 'wykresy-paulina',
    component: WykresyPaulina,
  },
  {
    path: '/wykresy-milena',
    name: 'wykresy-milena',
    component: WykresyMilena,
  },
  {
    path: '/wykresy-darek',
    name: 'wykresy-darek',
    component: WykresyDarek,
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router


