<template>
  <div class="relative h-screen">
    <GenproxNotification />
    <div class="simplebar flex w-full h-full overflow-y-auto">
      <div class="flex flex-1 flex-col justify-start px-4 py-12 sm:px-6 lg:flex-none lg:px-12 xl:px-24 h-full relative">
        <div class="mx-auto w-full max-w-sm lg:w-96 flex flex-col">
          <router-view></router-view>
        </div>
        <div class="mt-5 text-center text-sm text-gray-400 w-full bg-white p-3">
          <span class="align-middle">Your connection is protected by SSL</span>
          <Lock class="w-6 h-6 ml-2 inline-block align-middle" />
        </div>
      </div>
      <div class="sticky left-0 top-0 hidden w-0 h-screen flex-1 lg:block">
        <img class="absolute inset-0 h-full w-full object-cover"
          src="https://images.unsplash.com/photo-1496917756835-20cb06e75b4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1908&q=80"
          alt="">
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, toNative } from 'vue-facing-decorator'
import { Lock } from '@/components/icons/icons'
import GenproxNotification from '@/components/ui/GenproxNotification.vue'

@Component({
  components: { Lock, GenproxNotification }
})
export class GenproxModule extends Vue {}

export default toNative(GenproxModule)
</script>

<style lang="postcss"></style>

<docs></docs>