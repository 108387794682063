export const filters: Record<string, unknown> = {}

function addErrorHandler(filters: Record<string, unknown>) {
  filters.errorHandler = (error: any) => {
    const defaultMessage = "Something went wrong. Try again later.";
    let errorMessage = defaultMessage;

    if (error.response?.data?.label) {
      errorMessage = error.response.data.label;
    }

    if (errorMessage == "error.unexpected") {
      errorMessage = defaultMessage;
    }

    if (errorMessage == defaultMessage) {
      if (error.response?.data?.violations.length > 0) {
        errorMessage = `${capitalizeFirstLetter(
          error.response.data.violations[0].propertyPath
        )} - ${error.response.data.violations[0].title}`;
      }
    }

    if (errorMessage === "20c6a0ff-c6a6-4ca3-ab05-551523a98f61") {
      errorMessage = "Campaign is not active";
    }

    if (errorMessage === "c22631ff-28a0-4b5f-bbd6-14fcef1d69d9") {
      errorMessage = "Lead belongs to another advisor";
    }

    if (
      errorMessage == "9e4235af-9aa7-4489-b507-71851ff1448f" &&
      error.response?.data?.violations.length > 0
    ) {
      errorMessage = `${mapPropertyPath(
        error.response.data.violations[0].propertyPath
      )} - ${error.response.data.violations[0].message}`;
    }

    return errorMessage;
  }
  


  function capitalizeFirstLetter(string: string): string {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  function mapPropertyPath(value: string) {
    if (value == "counterPartyTin") {
      return "Counterparty TIN";
    } else if (value == "counterPartyName") {
      return "Counterparty Name";
    } else if (value == "counterPartyStreet") {
      return "Street";
    } else if (value == "counterPartyStreetNumber") {
      return "Street number";
    } else if (value == "counterPartyApartmentNumber") {
      return "Local";
    } else if (value == "counterPartyCity") {
      return "City";
    } else if (value == "counterPartyZipCode") {
      return "ZIP code";
    } else if (value == "counterPartyCountry") {
      return "Country";
    } else if (value == "type") {
      return "Document type";
    } else if (value == "documentNumber") {
      return "Document number";
    } else if (value == "correctedDocumentNumber") {
      return "Corrected document number";
    } else if (value == "issueDate") {
      return "Issue date";
    } else if (value == "paymentDate") {
      return "Payment date";
    } else if (value == "saleDate") {
      return "Sale date";
    } else if (value == "additionDate") {
      return "Addition date";
    } else if (value == "paymentMethod") {
      return "Payment method";
    } else if (value == "paymentStatus") {
      return "Payment status";
    } else if (value == "currency") {
      return "Currency";
    } else if (value == "bankAccount") {
      return "Bank account";
    } else if (value == "bankAccountCountry") {
      return "Bank account country";
    } else if (value == "glAccount") {
      return "GL account";
    } else if (value == "costCenter") {
      return "Cost center";
    }

    return value;
  }
}

addErrorHandler(filters)

filters.snakeCaseToTitleCase = (s: string) => {
  const formattedString = s?.toLowerCase().replace(/(^\w)/g, g => g[0].toUpperCase()).replace(/([-_]\w)/g, g => " " + g[1].toUpperCase()).trim();
  return formattedString;
}

filters.uppercase = (s: string) => {
  return s?.toUpperCase()
}