<template>
  <div class="login-form">
    <AuthModuleTitle title="Sign in to your account">
      Not a member?
      <GenproxLink class="text-black" to="/auth/register">Register</GenproxLink>
    </AuthModuleTitle>

    <div class="mt-10">
      <div>
        <FormKit type="form" id="login" :incomplete-message="false" form-class="space-y-6" :actions="false" @submit="login">
          <FormKit 
            label="Email address"
            v-model="loginCredentials.email"
            type="email"
            name="email"
            id="email"
            validation="required|email"
          />
          <FormKit 
            label="Password" 
            v-model="loginCredentials.password"
            type="password"
            name="password"
            id="password" 
            :validation="[['required'], ['matches', /^(?=.*[A-Z])(?=.*[!@#$&*^%])(?=.*[0-9])(?=.*[a-z]).{8,}$/]]"
            :validationMessages="{
              matches: 'Password is too weak. It must be at least 8 characters long and include one uppercase, one lowercase letter and number or special character.'
            }"
          />

          <div class="flex items-center justify-between">
            <FormKit
              outer-class="$remove:mb-4 flex"
              wrapper-class="$remove:mb-1"
              type="checkbox"
              id="rememberMe"
              name="rememberMe"
              decorator-icon="check"
              :disabled="true"
              label="Remember me"
            />
            <div class="text-sm leading-6">
              <GenproxLink to="/auth/remind-password">Forgot password?</GenproxLink>
            </div>
          </div>
  
          <div>
            <GenproxButton class="w-full" :isLoading="isLoading" type="submit">Sign in</GenproxButton>
          </div>
        </FormKit>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, toNative } from 'vue-facing-decorator'
import GenproxLink from '@/components/ui/GenproxLink.vue'
import GenproxInput from '@/components/ui/GenproxInput.vue'
import GenproxButton from '@/components/ui/GenproxButton.vue'
import GenproxCheckbox from '@/components/ui/GenproxCheckbox.vue'
import AuthModuleTitle from '@/partials/auth/AuthModuleTitle.vue'
import { LoginCredentialsDTO } from '@/store/modules/auth/types'


@Component({
  components: { AuthModuleTitle, GenproxLink, GenproxInput, GenproxButton, GenproxCheckbox },
})
export class LoginForm extends Vue {
  isLoading: boolean = false
  loginCredentials: LoginCredentialsDTO = {
    email: null,
    password: null,
  }

  /**
   * Function used when form is submitted <br />
   * @public
   * @param dispatch{/auth/login}
   * @param payload{LoginCredentialsDTO}
   */
  async login(): Promise<void> {
    this.isLoading = true

    try {
      await this.$store.dispatch('auth/login', this.loginCredentials)
      this.clearFormData()
    } catch (e) {
      console.log('err', e)
      const errorMessage = this.$filters.errorHandler(e)
      this.$notify({
        type: 'error',
        title: 'Error',
        text: errorMessage
      })
    }

    this.isLoading = false
  }

  /**
   * Function used for clearing form data
   * @public
   */
  clearFormData(): void {
    this.loginCredentials.password = null
    this.loginCredentials.email = null
  }
}

export default toNative(LoginForm)
</script>

<docs>
```html
Validations:
1. email
  - required
  - email
2. password
  - required
```
</docs>