<template>
  <div class="home p-8 h-screen overflow-y-auto">
    <div class="grid grid-cols-3 gap-4">
      <div v-for="(option, i) in graphs" :key="i" class="chart aspect-square bg-gray-100 rounded-lg">
        <v-chart :option="option" autoresize />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, toNative } from 'vue-facing-decorator'
import VChart from 'vue-echarts'

import 'echarts'

@Component({
  components: { VChart }
})
export class WykresyMilena extends Vue {
  sunburstData: any[] = [
    {
      name: 'Grandpa',
      children: [
        {
          name: 'Uncle Leo',
          value: 15,
          children: [
            {
              name: 'Cousin Jack',
              value: 2
            },
            {
              name: 'Cousin Mary',
              value: 5,
              children: [
                {
                  name: 'Jackson',
                  value: 2
                }
              ]
            },
            {
              name: 'Cousin Ben',
              value: 4
            }
          ]
        },
        {
          name: 'Father',
          value: 10,
          children: [
            {
              name: 'Me',
              value: 5
            },
            {
              name: 'Brother Peter',
              value: 1
            }
          ]
        }
      ]
    },
    {
      name: 'Nancy',
      children: [
        {
          name: 'Uncle Nike',
          children: [
            {
              name: 'Cousin Betty',
              value: 1
            },
            {
              name: 'Cousin Jenny',
              value: 2
            }
          ]
        }
      ]
    }
  ]

  get graphs() {
    return [
      {
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
        },
        yAxis: {
          type: 'value'
        },
        series: [
          {
            data: [820, 932, 901, 934, 1290, 1330, 1320],
            type: 'line',
            areaStyle: {}
          }
        ],
        tooltip: {
          trigger: 'axis'
        }
      },
      {
        series: {
          type: 'sunburst',
          data: this.sunburstData,
          radius: [0, '90%'],
          label: {
            rotate: 'radial'
          }
        }
      }
    ]
  }
}

export default toNative(WykresyMilena)
</script>
