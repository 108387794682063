/* eslint-disable */
import { createApp, configureCompat } from 'vue'
import App from './App.vue'

configureCompat({
  MODE: 3,
  COMPONENT_V_MODEL: false,
  OPTIONS_BEFORE_DESTROY: 'suppress-warning',
  OPTIONS_DESTROYED: 'suppress-warning',
  INSTANCE_LISTENERS: true,
  ATTR_FALSE_VALUE: 'suppress-warning',
})

import './registerServiceWorker'
import router from './router'
import store from './store/rootStore'

// import plugins
import { ExtendedStoreOptions } from './store/types'
import Notifications from '@/plugins/notifications'
import { notify } from '@kyvg/vue3-notification'
import { filters } from '@/plugins/filters'

// import 'vue3-perfect-scrollbar/style.css'
import './plugins/vClickOutside'
import './plugins/vuelidate'
import './plugins/iframes'

import { plugin, defaultConfig } from '@formkit/vue'
import config from '../formkit.config'

// import styles
import './styles/index.css'

const app = createApp(App)

app.config.globalProperties.$filters = filters;
(store as ExtendedStoreOptions).$notify = notify

app
  .use(Notifications)
  .use(router)
  .use(store)
  .use(plugin, defaultConfig(config))
.mount('#app')

