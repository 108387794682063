import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "mt-8 text-2xl font-bold leading-9 tracking-tight text-gray-900" }
const _hoisted_2 = { class: "mt-2 text-sm leading-6 text-gray-500" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("h2", _hoisted_1, _toDisplayString(_ctx.title), 1),
    _createElementVNode("p", _hoisted_2, [
      _renderSlot(_ctx.$slots, "default")
    ])
  ]))
}