<template>
  <div :class="['gn-radio', inputAttrs?.disabled ? 'disabled' : '']">
    <div class="flex flex-nowrap">
      <input :id="id" :value="inputValue" @input="handleInput(inputValue)" v-bind="{...$props?.inputAttrs}"
        class="gn-radio__input" type="radio">
      <div class="block w-full col-span-1">
        <label :for="id" class="gn-radio__label label">
          <!-- @slot Radio label content here -->
          <slot></slot>
        </label>
        <GenproxError :validation="validation" :id="`${id}-error`" />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Emit, Prop, toNative } from 'vue-facing-decorator'
import GenproxError from '@/components/ui/GenproxError.vue'

@Component({
  components: { GenproxError }
})
export class GenproxRadio extends Vue {
  @Prop({ default: null }) validation: Record<string, unknown>
  @Prop({ default: null }) inputAttrs: Record<string, unknown>
  @Prop({ default: null }) id: string
  @Prop() inputValue!: boolean

  modelValue: any = null

  @Emit('input')
  handleInput(inputValue: any) {
    this.modelValue = inputValue
    return this.modelValue
  }

  /**
   * Has error computed property
   * @public
   */
  get hasError(): boolean {
    return !!(this.validation?.$error)
  }

  mounted() {
    this.modelValue = this.inputValue
  }
}

export default toNative(GenproxRadio)
</script>

<style lang="postcss" scoped>
.gn-radio {
  @apply block;

  &.disabled {
    
    .gn-radio__input {
      @apply cursor-default;
    }

    .gn-radio__label {
      @apply cursor-default;
    }
  }

  .gn-radio__input {
    @apply appearance-none cursor-pointer flex-shrink-0 bg-clip-content p-1 w-4 h-4 mt-1 border border-gray-300 rounded-full mr-2 outline-none ring-offset-1 ring-gray-800;

    &:focus {
      @apply ring-2;
    }

    &:checked {
      @apply bg-gray-800;
    }
  }

  .gn-radio__label {
    @apply inline-block cursor-pointer;
  }
}
</style>

<docs>
```html
<GenproxCheckbox>Checkbox Label</GenproxCheckbox>
```
</docs>