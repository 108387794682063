<template>
  <div class="relative">
    <div class="absolute inset-0 flex items-center" aria-hidden="true">
      <div class="w-full border-t border-gray-200"></div>
    </div>
    <div class="relative flex justify-center text-sm font-medium leading-6">
      <span class="bg-white px-6 text-gray-900">
        <!-- @slot Page divider title -->
        <slot></slot>
      </span>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, toNative } from 'vue-facing-decorator'

@Component({
  components: {}
})
export class GenproxDivider extends Vue {}

export default toNative(GenproxDivider)
</script>

<docs>
```html
<GenproxDivider>Page divider title</GenproxDivider>
```
</docs>