<template>
  <router-link class="router-link" :to="to">
    <!-- @slot Link -->
    <slot></slot>
  </router-link>
</template>

<script lang="ts">
import { Component, Vue, Prop, toNative } from 'vue-facing-decorator'

@Component({
  components: {}
})
export class GenproxLink extends Vue {
  @Prop({ default: null }) to: string
}

export default toNative(GenproxLink)
</script>

<style lang="postcss" scoped>
a.router-link {
  @apply font-semibold no-underline;

  &:hover {
    @apply underline;
  }

  &.router-link-active {
    @apply underline;

    &:hover {
      @apply no-underline;
    }
  }
}
</style>

<docs>
```html
<GenproxLink to="/">Link</GenproxLink>
```
</docs>