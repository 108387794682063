import { mergeProps as _mergeProps, createElementVNode as _createElementVNode, renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-94bf9510"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "flex flex-nowrap" }
const _hoisted_2 = ["id", "value"]
const _hoisted_3 = { class: "block w-full col-span-1" }
const _hoisted_4 = ["for"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_GenproxError = _resolveComponent("GenproxError")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(['gn-radio', _ctx.inputAttrs?.disabled ? 'disabled' : ''])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("input", _mergeProps({
        id: _ctx.id,
        value: _ctx.inputValue,
        onInput: _cache[0] || (_cache[0] = ($event: any) => (_ctx.handleInput(_ctx.inputValue)))
      }, {..._ctx.$props?.inputAttrs}, {
        class: "gn-radio__input",
        type: "radio"
      }), null, 16, _hoisted_2),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("label", {
          for: _ctx.id,
          class: "gn-radio__label label"
        }, [
          _renderSlot(_ctx.$slots, "default", {}, undefined, true)
        ], 8, _hoisted_4),
        _createVNode(_component_GenproxError, {
          validation: _ctx.validation,
          id: `${_ctx.id}-error`
        }, null, 8, ["validation", "id"])
      ])
    ])
  ], 2))
}