import { resolveDynamicComponent as _resolveDynamicComponent, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createElementVNode as _createElementVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-aba84cd0"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["type"]
const _hoisted_2 = { class: "genprox-button__text" }
const _hoisted_3 = {
  key: 1,
  class: "genprox-button__text"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Loader = _resolveComponent("Loader")!

  return (_openBlock(), _createElementBlock("button", {
    type: _ctx.type,
    class: _normalizeClass(['genprox-button', _ctx.variant, _ctx.outline ? 'outline' : '', _ctx.isLoading ? 'is-loading' : '', `size-${_ctx.size}`]),
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleClick && _ctx.handleClick(...args)))
  }, [
    (!_ctx.isLoading)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          (_ctx.icon)
            ? (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.icon), {
                key: 0,
                class: _normalizeClass([_ctx.size === 'big' ? 'h-7 w-7' : 'h-5 w-5', _ctx.iconPosition === 'left' ? '-ml-0.5' : '-mr-0.5 order-2'])
              }, null, 8, ["class"]))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_2, [
            _renderSlot(_ctx.$slots, "default", {}, undefined, true)
          ])
        ], 64))
      : (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createVNode(_component_Loader, { class: "w-5 h-5" })
        ]))
  ], 10, _hoisted_1))
}