import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "reset-password-form" }
const _hoisted_2 = { class: "mt-10" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AuthModuleTitle = _resolveComponent("AuthModuleTitle")!
  const _component_FormKit = _resolveComponent("FormKit")!
  const _component_GenproxButton = _resolveComponent("GenproxButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_AuthModuleTitle, { title: "Reset your password" }, {
      default: _withCtx(() => [
        _createTextVNode(" Fill in a form to set your new password. ")
      ], undefined, true),
      _: 1
    }),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", null, [
        _createVNode(_component_FormKit, {
          type: "form",
          id: "resetPassword",
          "incomplete-message": false,
          "form-class": "space-y-6",
          actions: false,
          onSubmit: _ctx.resetPassword
        }, {
          default: _withCtx(() => [
            _createVNode(_component_FormKit, {
              label: "Password",
              modelValue: _ctx.password,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.password) = $event)),
              type: "password",
              name: "password",
              id: "password",
              validation: [['required'], ['matches', /^(?=.*[A-Z])(?=.*[!@#$&*^%])(?=.*[0-9])(?=.*[a-z]).{8,}$/]],
              validationMessages: {
              matches: 'Password is too weak. It must be at least 8 characters long and include one uppercase, one lowercase letter and number or special character.'
            }
            }, null, 8, ["modelValue", "validation", "validationMessages"]),
            _createVNode(_component_FormKit, {
              label: "Repeat password",
              modelValue: _ctx.repeatPassword,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.repeatPassword) = $event)),
              type: "password",
              name: "password_confirm",
              id: "password",
              validation: "required|confirm"
            }, null, 8, ["modelValue"]),
            _createElementVNode("div", null, [
              _createVNode(_component_GenproxButton, {
                class: "w-full",
                isLoading: _ctx.isLoading,
                type: "submit"
              }, {
                default: _withCtx(() => [
                  _createTextVNode("Set new password")
                ], undefined, true),
                _: 1
              }, 8, ["isLoading"])
            ])
          ], undefined, true),
          _: 1
        }, 8, ["onSubmit"])
      ])
    ])
  ]))
}