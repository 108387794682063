<template>
  <div class="reset-password-form">
    <AuthModuleTitle title="Activate your account">
      Set your password and start using our platform.
    </AuthModuleTitle>

    <div class="mt-10">
      <div>
        <form action="#" method="POST" class="space-y-6" @submit.prevent="activateAccount">
          <GenproxInput v-model="password" :inputAttrs="{ type: 'password', required: true }" :validation="v$?.password" id="password" label="Password" />
          <GenproxInput v-model="repeatPassword" :inputAttrs="{ type: 'password' }" :validation="v$?.repeatPassword" id="repeatPassword" label="Repeat password" />

          <div>
            <GenproxButton class="w-full" :isLoading="isLoading" type="submit">Activate account</GenproxButton>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Setup, Vue, toNative } from 'vue-facing-decorator'
import GenproxLink from '@/components/ui/GenproxLink.vue'
import GenproxInput from '@/components/ui/GenproxInput.vue'
import GenproxDivider from '@/components/ui/GenproxDivider.vue'
import GenproxButton from '@/components/ui/GenproxButton.vue'
import GenproxCheckbox from '@/components/ui/GenproxCheckbox.vue'
import AuthModuleTitle from '@/partials/auth/AuthModuleTitle.vue'
import { ActiveAccountPayloadDTO } from '@/store/modules/auth/types'

import { useVuelidate } from '@vuelidate/core'
import { required, sameAs } from '@vuelidate/validators'

@Component({
  components: { AuthModuleTitle, GenproxLink, GenproxInput, GenproxDivider, GenproxButton, GenproxCheckbox }
})
export class ActivateAccount extends Vue {
  isLoading: boolean = false
  password: string = null
  repeatPassword: string = null

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  @Setup((props, ctx) => useVuelidate())
  v$: any

  /**
   * Function used when form is submitted
   * @public
   * @param dispatch{/auth/activateAccountFromEmail}
   * @param payload{ActiveAccountPayloadDTO}
   */
  async activateAccount(): Promise<void> {
    this.v$?.$touch()
    if (!this.v$.$error) {
      this.isLoading = true
      try {
        const activateAccountData: ActiveAccountPayloadDTO = {
          plainPassword: this.password,
          token: this.$route.params?.id
        };
        await this.$store.dispatch('auth/activateAccountFromEmail', activateAccountData);
        await this.$router.push({ name: 'login' });
        this.$notify({
          type: 'success',
          title: 'Success',
          text: 'Your account has been activated'
        });
        this.clearFormData()
      } catch (e) {
        const errorMessage = this.$filters.errorHandler(e)
        this.$notify({
          type: 'error',
          title: 'Error',
          text: errorMessage
        });
      }
      this.isLoading = false
    }
  }

  /**
   * Function used for clearing form data
   * @public
   */
  clearFormData(): void {
    this.password = null
    this.repeatPassword = null
    this.v$?.$reset()
  }

  validations(): Record<string, unknown> {
    return {
      password: {
        required,
        strongPassword: (value: string) => {
          return Boolean(value?.match('^(?=.*[A-Z])(?=.*[!@#$&*^%])(?=.*[0-9])(?=.*[a-z]).{8,}$'));
        }
      },
      repeatPassword: {
        required,
        sameAsPassword: sameAs('password')
      },
    }
  }
}

export default toNative(ActivateAccount)
</script>

<docs>
```html
Validations:
1. password
  - required
  - is strong password (contains at least 8 characters long and includes one uppercase, one lowercase letter and number or special character )
2. repeatPassword
  - required
  - is the same as password from 1st point
```
</docs>