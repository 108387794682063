<template>
  <div>
    <!-- <img class="h-10 w-auto" src="@/assets/favicon.svg" alt="Fundequate"> -->
    <h2 class="mt-8 text-2xl font-bold leading-9 tracking-tight text-gray-900">{{ title }}</h2>
    <p class="mt-2 text-sm leading-6 text-gray-500">
      <!-- @slot Description content goes here -->
      <slot></slot>
    </p>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop, toNative } from 'vue-facing-decorator';

@Component({
  components: {}
})
export class AuthModuleTitle extends Vue {
  @Prop({ default: 'Fundequate' }) title: string
}

export default toNative(AuthModuleTitle)
</script>

<docs>
```html
<AuthModuleTitle title="Reset your password">
  Enter your email and we'll send you a link to reset your password.
</AuthModuleTitle>
```
</docs>