<template>
  <div class="reset-password-form">
    <AuthModuleTitle title="Reset your password">
      Fill in a form to set your new password.
    </AuthModuleTitle>

    <div class="mt-10">
      <div>
        <FormKit type="form" id="resetPassword" :incomplete-message="false" form-class="space-y-6" :actions="false" @submit="resetPassword">
          <FormKit 
            label="Password" 
            v-model="password"
            type="password"
            name="password"
            id="password" 
            :validation="[['required'], ['matches', /^(?=.*[A-Z])(?=.*[!@#$&*^%])(?=.*[0-9])(?=.*[a-z]).{8,}$/]]"
            :validationMessages="{
              matches: 'Password is too weak. It must be at least 8 characters long and include one uppercase, one lowercase letter and number or special character.'
            }"
          />
          <FormKit 
            label="Repeat password" 
            v-model="repeatPassword"
            type="password"
            name="password_confirm"
            id="password" 
            validation="required|confirm"
          />

          <div>
            <GenproxButton class="w-full" :isLoading="isLoading" type="submit">Set new password</GenproxButton>
          </div>
        </FormKit>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Setup, Vue, toNative } from 'vue-facing-decorator'
import GenproxLink from '@/components/ui/GenproxLink.vue'
import GenproxInput from '@/components/ui/GenproxInput.vue'
import GenproxDivider from '@/components/ui/GenproxDivider.vue'
import GenproxButton from '@/components/ui/GenproxButton.vue'
import GenproxCheckbox from '@/components/ui/GenproxCheckbox.vue'
import AuthModuleTitle from '@/partials/auth/AuthModuleTitle.vue'
import { ResetPasswordDataDTO } from '@/store/modules/auth/types'

import { useVuelidate } from '@vuelidate/core'
import { required, sameAs } from '@vuelidate/validators'

@Component({
  components: { AuthModuleTitle, GenproxLink, GenproxInput, GenproxDivider, GenproxButton, GenproxCheckbox }
})
export class ResetPassword extends Vue {
  isLoading: boolean = false
  password: string = null
  repeatPassword: string = null

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  @Setup((props, ctx) => useVuelidate())
  v$: any

  /**
   * Function used when form is submitted
   * @public
   * @param dispatch{/auth/resetPassword}
   * @param payload{ResetPasswordDataDTO}
   */
  async resetPassword(): Promise<void> {
    this.v$?.$touch()

    if (!this.v$?.$error) {
      this.isLoading = true
      try {
        const resetPasswordData: ResetPasswordDataDTO = {
          plainPassword: this.password,
          token: this.$route.params?.id
        }

        await this.$store.dispatch('auth/resetPassword', resetPasswordData)
        await this.$router.push({ name: 'login' })

        this.$notify({
          type: 'success',
          title: 'Success',
          text: 'Password has been changed'
        });

        this.clearFormData()
      } catch (e) {
        const errorMessage = this.$filters.errorHandler(e)
        this.$notify({
          type: 'error',
          title: 'Error',
          text: errorMessage
        })
      }
      this.isLoading = false
    }
  }

  /**
   * Function used for clearing form data
   * @public
   */
  clearFormData(): void {
    this.password = null
    this.repeatPassword = null
    this.v$?.$reset()
  }

  validations(): Record<string, unknown> {
    return {
      password: {
        required,
        strongPassword: (value: string) => {
          return Boolean(value?.match('^(?=.*[A-Z])(?=.*[!@#$&*^%])(?=.*[0-9])(?=.*[a-z]).{8,}$'));
        }
      },
      repeatPassword: { 
        required,
        sameAsPassword: sameAs('password')
      },
    }
  }
}

export default toNative(ResetPassword)
</script>

<docs>
```html
Validations:
1. password
  - required
  - is strong password (contains at least 8 characters long and includes one uppercase, one lowercase letter and number or special character )
2. repeatPassword
  - required
  - is the same as password from 1st point
```
</docs>