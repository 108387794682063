<template>
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
    <path stroke-linecap="round" stroke-linejoin="round" d="m19.5 4.5-15 15m0 0h11.25m-11.25 0V8.25" />
  </svg>
</template>

<script lang="ts">
import { Component, Vue, toNative } from 'vue-facing-decorator'

@Component({
  components: {}
})
export class ArrowDownLeft extends Vue {}

export default toNative(ArrowDownLeft)
</script>

<docs>
```html
<ArrowDownLeft />
```
</docs>