<template>
  <div class="reset-password-form">
    <AuthModuleTitle title="Confirm your login">
      Enter Google Authenticator code to confirm your signing.
    </AuthModuleTitle>

    <div class="mt-10">
      <div>
        <FormKit type="form" id="sendConfirmationCode" :incomplete-message="false" form-class="space-y-6" :actions="false" @submit="sendConfirmationCode">
          <div>
            <FormKit 
              label="Google Authenticator code"
              v-model="confirmationCode"
              type="text"
              name="googleAuthenticatorCode"
              id="googleAuthenticatorCode"
              validation="required|length:6,6|number"
            />
            <!-- <GenproxInput 
              v-model="confirmationCode"
              label="Google Authenticator code" 
              :inputAttrs="{ required: true }" 
              :validation="v$?.confirmationCode"
              id="googleAuthenticatorCode"
            /> -->
          </div>
          <p class="text-red-500 text-sm text-right">Your session ends in: {{ timerString }}</p>
          <div>
            <GenproxButton class="w-full" :isLoading="isLoading" variant="black" type="submit">Sign in</GenproxButton>
          </div>
        </FormKit>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, toNative } from 'vue-facing-decorator'
import GenproxLink from '@/components/ui/GenproxLink.vue'
import GenproxInput from '@/components/ui/GenproxInput.vue'
import GenproxButton from '@/components/ui/GenproxButton.vue'
import GenproxCheckbox from '@/components/ui/GenproxCheckbox.vue'
import AuthModuleTitle from '@/partials/auth/AuthModuleTitle.vue'
import { REDIRECTED_PATH } from '@/shared/consts'
import { LoginWithTwoFactorDTO } from '@/store/modules/auth/types'
import Utils from '@/shared/utils/utils'

@Component({
  components: { AuthModuleTitle, GenproxLink, GenproxInput, GenproxButton, GenproxCheckbox },
})
export class LoginConfirmForm extends Vue {
  confirmationCode: string = null
  sessionLength: number = 300
  isLoading: boolean = false
  interval: any = null

  /**
   * Function used when form is submitted
   * @public
   * @param dispatch{/auth/loginWithTwoFactor}
   * @param payload{LoginWithTwoFactorDTO}
   */
  async sendConfirmationCode(): Promise<void> {
    this.isLoading = true;

    try {
      const payload: LoginWithTwoFactorDTO = {
        token: this.$route.params.token,
        confirmationCode: this.confirmationCode
      }

      await this.$store.dispatch('auth/loginWithTwoFactor', payload)
      localStorage.removeItem(REDIRECTED_PATH);
    } catch (e) {
      const errorMessage = this.$filters.errorHandler(e)
      this.$notify({
        duration: 2500,
        type: 'error',
        title: 'Error',
        text: errorMessage
      });
    }

    this.isLoading = false
  }

  /**
   * Function used to get string of remaining session time
   * @public
   */
  get timerString(): string {
    const minutes = Math.floor(this.sessionLength / 60);
    const seconds = this.sessionLength % 60;

    return `${Utils.pad(minutes, 2)}:${Utils.pad(seconds, 2)}`;
  }

  /**
   * Function used for initializing session timer <br>
   * If session ends we're redirected to login page
   * @public
   * 
   */
  beforeMount() {
    this.interval = setInterval(() => {
      this.sessionLength--;

      if (this.sessionLength <= 0) {
        this.$router.push({ name: 'login' });
      }
    }, 1000);
  }

  /**
   * Function used for clearing session timer interval <br>
   * Used when page is being destroyed (for example: page is refreshed)
   * @public
   */
  beforeUnmount() {
    clearInterval(this.interval);
  }
}

export default toNative(LoginConfirmForm)
</script>

<docs>
```html
Validations:
1. confirmationCode
  - required
  - minLength: 6
  - maxLength: 6
```
</docs>