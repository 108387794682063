<template>
  <button :type="type" :class="['genprox-button', variant, outline ? 'outline' : '', isLoading ? 'is-loading' : '', `size-${size}`]" @click="handleClick">
    <template v-if="!isLoading">
      <template v-if="icon">
        <component :is="icon" :class="[size === 'big' ? 'h-7 w-7' : 'h-5 w-5', iconPosition === 'left' ? '-ml-0.5' : '-mr-0.5 order-2']"></component>
      </template>
      <!-- @slot Button content goes here -->
      <div class="genprox-button__text">
        <slot></slot>
      </div>
    </template>
    <template v-else>
      <div class="genprox-button__text">
        <Loader class="w-5 h-5" />
      </div>
    </template>
  </button>
</template>

<script lang="ts">
import { Component, Vue, Prop, toNative } from 'vue-facing-decorator'
import { ColorVariant } from '@/shared/types/variables'
import { Loader } from '@/components/icons/icons'

@Component({
  components: { Loader },
  emits: ['click']
})
export class GenproxButton extends Vue {
  @Prop({ default: 'right' }) iconPosition: 'left' | 'right'
  @Prop({ default: 'button' }) type: 'button' | 'submit'
  @Prop({ default: 'normal' }) size: 'normal' | 'big'
  @Prop({ default: 'black' }) variant: ColorVariant
  @Prop({ default: false }) selectable: boolean
  @Prop({ default: false }) isLoading: boolean
  @Prop({ default: false }) outline: boolean
  @Prop({ default: null }) icon: null | Vue
  @Prop({ default: '' }) tooltip: string

  /**
   * Click method handler
   * @public
   */
  handleClick(e: Event): void {
    /**
     * Emit click event
     * @property {Event} e
     */
    this.$emit('click', e)
  }
}

export default toNative(GenproxButton)
</script>

<style lang="postcss" scoped>
.genprox-button {
  @apply inline-flex items-center text-left gap-x-2 rounded-md px-3 py-2 outline-none justify-center text-sm font-semibold text-white shadow-sm;

  &:focus, &:active {
    @apply focus:ring ring-2 ring-gray-700;
  }

  &.is-loading {
    @apply pointer-events-none;
  }

  &.size-big {
    @apply px-6 py-4 text-lg;
  }

  &.black {
    @apply bg-black text-white;

    &.outline {
      @apply border border-black text-black bg-transparent;
    }
  }

  &.white {
    @apply bg-white text-black;

    &.outline {
      @apply bg-white border border-gray-300 text-black;
    }
  }

  &.red {
    @apply bg-red-500 text-white;

    &.outline {
      @apply bg-transparent border border-red-500 text-red-500;
    }
  }

  &.blue {
    @apply bg-blue-500 text-white;
    
    &.outline {
      @apply bg-transparent border border-blue-500 text-blue-500;
    }
  }
  
  &.yellow {
    @apply bg-yellow-300 text-white;
    
    &.outline {
      @apply bg-transparent border border-yellow-300 text-yellow-300;
    }
  }

  &.orange {
    @apply bg-yellow-500 text-white;
    
    &.outline {
      @apply bg-transparent border border-yellow-500 text-yellow-500;
    }
  }

  &.green {
    @apply bg-green-500 text-white;
    
    &.outline {
      @apply bg-transparent border border-green-500 text-green-500;
    }
  }

  &.purple {
    @apply bg-purple-500 text-white;
    
    &.outline {
      @apply bg-transparent border border-purple-500 text-purple-500;
    }
  }

  &.indigo {
    @apply bg-indigo-500 text-white;
    
    &.outline {
      @apply bg-transparent border border-indigo-500 text-indigo-500;
    }
  }

  &.pink {
    @apply bg-pink-500 text-white;
    
    &.outline {
      @apply bg-transparent border border-pink-500 text-pink-500;
    }
  }

  &.gray {
    @apply bg-gray-500 text-white;
    
    &.outline {
      @apply bg-transparent border border-gray-500 text-gray-500;
    }
  }

  &__text {
    @apply inline-block;
  }
}
</style>

<docs>
```html
<GenproxButton>Example Button</GenproxButton>
```
</docs>