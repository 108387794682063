<template>
  <notifications position="top right" :max="3" :duration="5000" width="24rem">
    <template #body="props">
      <!-- Global notification live region, render this permanently at the end of the document -->
      <div aria-live="assertive"
        class="pointer-events-none inset-0 flex items-end px-4 py-4 sm:items-start sm:p-6 sm:py-4">
        <div class="flex w-full flex-col items-center space-y-4 sm:items-end">
          <div class="pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5">
            <div class="p-4 relative">
              <div class="flex items-start">
                <div class="flex-shrink-0">
                  <CheckCircle v-if="props.item.type === 'success'" class="h-6 w-6 text-green-500" />
                  <InformationCircle v-if="props.item.type === 'warn'" class="h-6 w-6 text-yellow-500" />
                  <ExclamationCircle v-if="props.item.type === 'error'" class="h-6 w-6 text-red-600" />
                </div>
                <div class="ml-3 w-0 flex-1 pt-0.5">
                  <p class="text-sm font-medium text-gray-900">{{ props.item.title }}</p>
                  <p class="mt-1 text-sm text-gray-500">{{ props.item.text }}</p>
                </div>
                <div class="ml-4 flex flex-shrink-0">
                  <button type="button" @click="props.close"
                    class="inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-gray-800 focus:ring-offset-2">
                    <span class="sr-only">Close</span>
                    <XMark class="w-5 h-5" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </notifications>
</template>

<script lang="ts">
import { Component, Vue, toNative } from 'vue-facing-decorator'
import { CheckCircle, InformationCircle, ExclamationCircle, XMark } from '@/components/icons/icons'

@Component({
  components: { CheckCircle, InformationCircle, ExclamationCircle, XMark }
})
export class GenproxNotification extends Vue {}

export default toNative(GenproxNotification)
</script>

<docs>
```html
<GenproxNotification />
```
</docs>