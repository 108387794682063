import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "reset-password-form" }
const _hoisted_2 = { class: "mt-10" }
const _hoisted_3 = { class: "text-red-500 text-sm text-right" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AuthModuleTitle = _resolveComponent("AuthModuleTitle")!
  const _component_FormKit = _resolveComponent("FormKit")!
  const _component_GenproxButton = _resolveComponent("GenproxButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_AuthModuleTitle, { title: "Confirm your login" }, {
      default: _withCtx(() => [
        _createTextVNode(" Enter Google Authenticator code to confirm your signing. ")
      ], undefined, true),
      _: 1
    }),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", null, [
        _createVNode(_component_FormKit, {
          type: "form",
          id: "sendConfirmationCode",
          "incomplete-message": false,
          "form-class": "space-y-6",
          actions: false,
          onSubmit: _ctx.sendConfirmationCode
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", null, [
              _createVNode(_component_FormKit, {
                label: "Google Authenticator code",
                modelValue: _ctx.confirmationCode,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.confirmationCode) = $event)),
                type: "text",
                name: "googleAuthenticatorCode",
                id: "googleAuthenticatorCode",
                validation: "required|length:6,6|number"
              }, null, 8, ["modelValue"])
            ]),
            _createElementVNode("p", _hoisted_3, "Your session ends in: " + _toDisplayString(_ctx.timerString), 1),
            _createElementVNode("div", null, [
              _createVNode(_component_GenproxButton, {
                class: "w-full",
                isLoading: _ctx.isLoading,
                variant: "black",
                type: "submit"
              }, {
                default: _withCtx(() => [
                  _createTextVNode("Sign in")
                ], undefined, true),
                _: 1
              }, 8, ["isLoading"])
            ])
          ], undefined, true),
          _: 1
        }, 8, ["onSubmit"])
      ])
    ])
  ]))
}