import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "login-form" }
const _hoisted_2 = { class: "mt-10" }
const _hoisted_3 = { class: "flex items-center justify-between" }
const _hoisted_4 = { class: "text-sm leading-6" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_GenproxLink = _resolveComponent("GenproxLink")!
  const _component_AuthModuleTitle = _resolveComponent("AuthModuleTitle")!
  const _component_FormKit = _resolveComponent("FormKit")!
  const _component_GenproxButton = _resolveComponent("GenproxButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_AuthModuleTitle, { title: "Sign in to your account" }, {
      default: _withCtx(() => [
        _createTextVNode(" Not a member? "),
        _createVNode(_component_GenproxLink, {
          class: "text-black",
          to: "/auth/register"
        }, {
          default: _withCtx(() => [
            _createTextVNode("Register")
          ], undefined, true),
          _: 1
        })
      ], undefined, true),
      _: 1
    }),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", null, [
        _createVNode(_component_FormKit, {
          type: "form",
          id: "login",
          "incomplete-message": false,
          "form-class": "space-y-6",
          actions: false,
          onSubmit: _ctx.login
        }, {
          default: _withCtx(() => [
            _createVNode(_component_FormKit, {
              label: "Email address",
              modelValue: _ctx.loginCredentials.email,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.loginCredentials.email) = $event)),
              type: "email",
              name: "email",
              id: "email",
              validation: "required|email"
            }, null, 8, ["modelValue"]),
            _createVNode(_component_FormKit, {
              label: "Password",
              modelValue: _ctx.loginCredentials.password,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.loginCredentials.password) = $event)),
              type: "password",
              name: "password",
              id: "password",
              validation: [['required'], ['matches', /^(?=.*[A-Z])(?=.*[!@#$&*^%])(?=.*[0-9])(?=.*[a-z]).{8,}$/]],
              validationMessages: {
              matches: 'Password is too weak. It must be at least 8 characters long and include one uppercase, one lowercase letter and number or special character.'
            }
            }, null, 8, ["modelValue", "validation", "validationMessages"]),
            _createElementVNode("div", _hoisted_3, [
              _createVNode(_component_FormKit, {
                "outer-class": "$remove:mb-4 flex",
                "wrapper-class": "$remove:mb-1",
                type: "checkbox",
                id: "rememberMe",
                name: "rememberMe",
                "decorator-icon": "check",
                disabled: true,
                label: "Remember me"
              }),
              _createElementVNode("div", _hoisted_4, [
                _createVNode(_component_GenproxLink, { to: "/auth/remind-password" }, {
                  default: _withCtx(() => [
                    _createTextVNode("Forgot password?")
                  ], undefined, true),
                  _: 1
                })
              ])
            ]),
            _createElementVNode("div", null, [
              _createVNode(_component_GenproxButton, {
                class: "w-full",
                isLoading: _ctx.isLoading,
                type: "submit"
              }, {
                default: _withCtx(() => [
                  _createTextVNode("Sign in")
                ], undefined, true),
                _: 1
              }, 8, ["isLoading"])
            ])
          ], undefined, true),
          _: 1
        }, 8, ["onSubmit"])
      ])
    ])
  ]))
}