<template>
  <div class="register-form">
    <template v-if="!isRegistrationCompleted">
      <AuthModuleTitle title="Create a new account">
        Already have an account?
        <GenproxLink class="text-black" to="/auth/login">Sign in</GenproxLink>
      </AuthModuleTitle>

      <div class="mt-10">
        <div>
          <FormKit type="form" id="login" :incomplete-message="false" form-class="space-y-6" :actions="false" @submit="register">
            <FormKit 
              label="Email address"
              v-model="email"
              type="email"
              name="email"
              id="email"
              :disabled="$route?.params?.userData ? true : false"
              validation="required|email"
            />
            <FormKit 
              label="First name"
              v-model="name"
              type="text"
              name="firstName"
              id="firstName"
              validation="required"
            />
            <FormKit 
              label="Surname"
              v-model="surname"
              type="text"
              name="surname"
              id="surname"
              validation="required"
            />
            <FormKit 
              label="Password" 
              v-model="password"
              type="password"
              name="password"
              id="password" 
              :validation="[['required'], ['matches', /^(?=.*[A-Z])(?=.*[!@#$&*^%])(?=.*[0-9])(?=.*[a-z]).{8,}$/]]"
              :validationMessages="{
                matches: 'Password is too weak. It must be at least 8 characters long and include one uppercase, one lowercase letter and number or special character.'
              }"
            />
            <FormKit 
              label="Repeat password" 
              v-model="repeatPassword"
              type="password"
              name="password_confirm"
              id="password" 
              validation="required|confirm"
            />

            <div v-if="!isRegisterFromMail" class="mt-8">
              <FormKit v-if="!isRegisterFromMail"
                v-model="investorType"
                type="radio"
                label="I am"
                legend-class="$remove:mb-2 mb-4"
                decorator-icon="circle"
                :options="[
                  { label: 'Fund Manager', value: UserTypes.fundManager, attrs: { disabled: true } },
                  { label: 'Startup Founder', value: UserTypes.startupFounder, attrs: { disabled: true } },
                  { label: 'Private Investor', value: UserTypes.privateInvestor, attrs: { disabled: true } },
                ]"
              />
            </div>

            <GenproxDivider />

            <FormKit
              v-model="agreement"
              type="checkbox"
              id="privacyPolicy"
              name="privacyPolicy"
              decorator-icon="check"
              validation="accepted"
              wrapper-class="$remove:items-center"
              :validation-messages="{ accepted: 'Please accept privacy policy.' }"
              message-class="ml-7"
            >
              <template #label>
                <div class="text-xs ml-1 mt-0.5">
                  I consent to the processing of my data in accordance with GDPR and declare that I have read the
                  <GenproxLink @click="showRegulations">privacy policy and terms and conditions</GenproxLink>
                </div>
              </template>
            </FormKit>

            <div>
              <GenproxButton class="w-full" :isLoading="isLoading" type="submit">Register</GenproxButton>
            </div>
          </FormKit>
        </div>

        <div v-if="!isRegisterFromMail" class="mt-8">
          <GenproxDivider>or</GenproxDivider>

          <div class="mt-6 block">
            <GenproxButton class="w-full" variant="white" :outline="true" @click="showDemoModal = true">Request a demo</GenproxButton>
          </div>
        </div>
      </div>
    </template>
    <template v-else>
      <div class="mt-8 text-center">
        <div class="w-16 h-16 text-green-500 mb-6 mx-auto">
          <CheckCircle class="w-full h-full object-contain" />
        </div>
        <p class="text-2xl font-bold leading-9 tracking-tight text-gray-900">Registration completed</p>
        <p class="text-sm leading-6 text-gray-500">Please activate your account by email.</p>
      </div>
    </template>
    <DemoModal v-if="!isRegisterFromMail && showDemoModal" @close="showDemoModal = false" />
  </div>
</template>

<script lang="ts">
import { Component, Vue, toNative } from 'vue-facing-decorator'
import GenproxLink from '@/components/ui/GenproxLink.vue'
import GenproxInput from '@/components/ui/GenproxInput.vue'
import GenproxButton from '@/components/ui/GenproxButton.vue'
import GenproxDivider from '@/components/ui/GenproxDivider.vue'
import GenproxCheckbox from '@/components/ui/GenproxCheckbox.vue'
import GenproxRadio from '@/components/ui/GenproxRadio.vue'
import GenproxError from '@/components/ui/GenproxError.vue'
import DemoModal from '@/components/DemoModal.vue'
import AuthModuleTitle from '@/partials/auth/AuthModuleTitle.vue'
import { CheckCircle } from '@/components/icons/icons'
import { UserTypes } from '@/shared/consts'
import { RegisterDTO } from '@/store/modules/auth/types'

@Component({
  components: { AuthModuleTitle, GenproxLink, GenproxInput, GenproxButton, GenproxDivider, GenproxCheckbox, GenproxRadio, GenproxError, DemoModal, CheckCircle }
})
export class RegisterForm extends Vue {
  isRegistrationCompleted: boolean = false
  UserTypes = UserTypes
  email: string = null
  name: string = null
  surname: string = null
  password: string = null
  repeatPassword: string = null
  registrationToken: string | string[] = null
  registerProductId: string = null
  showDemoModal: boolean = false

  investorType: UserTypes = null
  agreement: boolean = false
  isLoading: boolean = false

  /**
   * Function used when form is submitted
   * @public
   * @param dispatch{/auth/register}
   * @param payload{RegisterDTO}
   */
  async register(): Promise<void> {
    const payload: RegisterDTO = this.getRegisterPayload()
    try {
      this.isLoading = true
      await this.$store.dispatch('auth/register', payload)
      if (this.investorType === UserTypes.privateInvestor) {
        await this.$store.dispatch('auth/login', {
          email: this.email,
          password: this.password,
        })
      } else if (this.investorType === UserTypes.fundManager) {
        this.isRegistrationCompleted = true
      }
    } catch (e: any) {
      const errorMessage = this.$filters.errorHandler(e)
      this.$notify({
        type: 'error',
        title: 'Error',
        text: errorMessage
      })
    }
    this.isLoading = false
  }

  /**
   * Computed property determining if user is using registration from email functionality
   * @public
   */
  get isRegisterFromMail() {
    return this.$route?.params?.userData ? true : false
  }

  /**
   * Function used for getting user data <br>
   * Used only when registering from email
   * @public
   */
  loadUserData() {
    const userData: any = this.$route.params.userData
    this.registrationToken = this.$route.params.registrationToken
    this.email = userData.email
    this.name = userData.firstName
    this.surname = userData.surname
    this.investorType = UserTypes.privateInvestor
    this.registerProductId = localStorage.getItem('registerProductId')
  }

  /**
   * Function used for getting the register action payload
   * @public
   */
  getRegisterPayload(): RegisterDTO {
    const payload: RegisterDTO = {
      email: this.email,
      plainPassword: this.password,
      firstName: this.name,
      surname: this.surname,
      type: this.investorType,
      token: null,
    };
    if (this.registrationToken) {
      payload.token = this.registrationToken
    }
    return payload
  }

  /**
   * Function used to resolve regulations path and open page in new tab
   * @public
   */
  async showRegulations(): Promise<void> {
    let routeData = this.$router.resolve({ name: 'regulations' })
    window.open(routeData.href, '_blank')
  }

  /**
   * Function used for preparing view based on registration type <br>
   * <ul>
   * <li>If register is from mail then load user data</li>
   * <li>If register is not from mail set user type to Fund Manager</li>
   * </ul>
   * @public
   */
  mounted() {
    if(this.$route.params.userData) {
      this.loadUserData()
    } else {
      this.investorType = UserTypes.fundManager
    }
  }

  setInvestorType(type: UserTypes) {
    this.investorType = type
  }

  setAgreement(value: boolean) {
    this.agreement = value
  }
}

export default toNative(RegisterForm)
</script>

<docs>
```html
Validations:
1. email
  - required
  - email
2. name
  - required
3. surname
  - required
4. password
  - required
  - is strong password (contains at least 8 characters long and includes one uppercase, one lowercase letter and number or special character )
5. repeatPassword
  - required
  - is the same as password from 4th point
6. investorType
  - required
7. agreement
  - required
  - needs to be checked
```
</docs>