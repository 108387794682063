import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, renderSlot as _renderSlot } from "vue"

const _hoisted_1 = { class: "genprox-error" }
const _hoisted_2 = {
  key: 0,
  class: "mt-2 text-sm text-red-600"
}
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { key: 1 }
const _hoisted_5 = { key: 2 }
const _hoisted_6 = { key: 3 }
const _hoisted_7 = { key: 4 }
const _hoisted_8 = { key: 5 }
const _hoisted_9 = { key: 6 }
const _hoisted_10 = { key: 7 }
const _hoisted_11 = { key: 8 }
const _hoisted_12 = { key: 9 }
const _hoisted_13 = { key: 10 }
const _hoisted_14 = { key: 11 }
const _hoisted_15 = { key: 12 }
const _hoisted_16 = { key: 13 }
const _hoisted_17 = { key: 14 }
const _hoisted_18 = { key: 15 }
const _hoisted_19 = {
  key: 1,
  class: "errors-container"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.validation && _ctx.validation?.$error && !_ctx.useCustomError)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (_ctx.validation.required === false)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, " This field is required "))
            : _createCommentVNode("", true),
          (_ctx.validation.minLength === false)
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, " Minimum length is " + _toDisplayString(_ctx.validation.$params.minLength.min), 1))
            : _createCommentVNode("", true),
          (_ctx.validation.maxLength === false)
            ? (_openBlock(), _createElementBlock("div", _hoisted_5, " Maximum length is " + _toDisplayString(_ctx.validation.$params.maxLength.max), 1))
            : _createCommentVNode("", true),
          (_ctx.validation.email === false)
            ? (_openBlock(), _createElementBlock("div", _hoisted_6, " This field must be valid email "))
            : _createCommentVNode("", true),
          (_ctx.validation.strongPassword === false)
            ? (_openBlock(), _createElementBlock("div", _hoisted_7, " Password is too weak. It must be at least 8 characters long and include one uppercase, one lowercase letter and number or special character. "))
            : _createCommentVNode("", true),
          (_ctx.validation.sameAsPassword === false)
            ? (_openBlock(), _createElementBlock("div", _hoisted_8, " Passwords must be same "))
            : _createCommentVNode("", true),
          (_ctx.validation.minValue === false)
            ? (_openBlock(), _createElementBlock("div", _hoisted_9, " Minimum value is " + _toDisplayString(_ctx.validation.$params.minValue.min), 1))
            : _createCommentVNode("", true),
          (_ctx.validation.maxValue === false)
            ? (_openBlock(), _createElementBlock("div", _hoisted_10, " Maximum value is " + _toDisplayString(_ctx.validation.$params.maxValue.max), 1))
            : _createCommentVNode("", true),
          (_ctx.validation.wrongDate === false)
            ? (_openBlock(), _createElementBlock("div", _hoisted_11, " Invalid date "))
            : _createCommentVNode("", true),
          (_ctx.validation.zipCode === false)
            ? (_openBlock(), _createElementBlock("div", _hoisted_12, " Provide correct zip code "))
            : _createCommentVNode("", true),
          (_ctx.validation.bankAccount === false)
            ? (_openBlock(), _createElementBlock("div", _hoisted_13, " Incorrect bank account number "))
            : _createCommentVNode("", true),
          (_ctx.validation.checked === false)
            ? (_openBlock(), _createElementBlock("div", _hoisted_14, " This field must be checked "))
            : _createCommentVNode("", true),
          (_ctx.validation.phone === false)
            ? (_openBlock(), _createElementBlock("div", _hoisted_15, " Invalid phone number "))
            : _createCommentVNode("", true),
          (_ctx.validation.url === false)
            ? (_openBlock(), _createElementBlock("div", _hoisted_16, " Invalid url "))
            : _createCommentVNode("", true),
          (_ctx.validation.iban === false)
            ? (_openBlock(), _createElementBlock("div", _hoisted_17, " Invalid Bank Account number "))
            : _createCommentVNode("", true),
          (_ctx.validation.hasCorrectAlready === false)
            ? (_openBlock(), _createElementBlock("div", _hoisted_18, " Correct already exists "))
            : _createCommentVNode("", true),
          _renderSlot(_ctx.$slots, "default")
        ]))
      : _createCommentVNode("", true),
    (_ctx.validation && _ctx.validation?.$error && _ctx.useCustomError)
      ? (_openBlock(), _createElementBlock("div", _hoisted_19, [
          _renderSlot(_ctx.$slots, "default")
        ]))
      : _createCommentVNode("", true)
  ]))
}