import Vue from 'vue'
import Vuex, { StoreOptions } from 'vuex'

import { RootState } from '@/store/types'

import { auth } from '@/store/modules/auth'
import { genprox } from '@/store/modules/genprox'

Vue.use((Vuex as any))

const debug = process.env.NODE_ENV !== 'production'

const store: StoreOptions<RootState> = {
  modules: {
    auth,
    genprox
  },
  strict: true,
  plugins: debug ? [] : [],
};

export default new Vuex.Store<RootState>(store);
