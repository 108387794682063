<template>
  <TransitionRoot as="template" :show="open">
    <Dialog as="div" class="contexts-modal relative z-50" @close="$emit('close')">
      <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100"
        leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
        <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
      </TransitionChild>
      <div class="fixed inset-0 z-50 w-screen overflow-y-auto">
        <div class="flex min-h-full items-center justify-center p-4 text-center sm:p-0">
          <TransitionChild as="template" enter="ease-out duration-300"
            enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200"
            leave-from="opacity-100 translate-y-0 sm:scale-100"
            leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
            <DialogPanel
              class="simplebar relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all cursor-auto sm:my-8 contexts-modal__container">
              <div @click.stop class="bg-white p-6 pb-4 sticky left-0 top-0 w-full z-10">
                <div class="absolute right-0 top-0 block pr-4 pt-4">
                  <button type="button" @click="$emit('close')"
                    class="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-gray-800 focus:ring-offset-2">
                    <span class="sr-only">Close</span>
                    <XMark class="h-6 w-6" />
                  </button>
                </div>
                <h3 class="text-lg font-semibold leading-6 text-gray-900 pb-3 pr-6 border-b border-gray-200"
                  id="contexts-modal-title">Switch your context</h3>
                <GenproxInput :icon="magnifyingGlass" :inputAttrs="{ placeholder: 'Search' }" class="mt-4" />
              </div>
              <div @click.stop class="bg-white px-4 sm:px-6">
                <!-- Empty state, show/hide based on command palette state -->
                <div v-if="false" class="px-6 py-14 text-center text-sm sm:px-14">
                  <svg class="mx-auto h-6 w-6 text-gray-400" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                    stroke="currentColor" aria-hidden="true">
                    <path stroke-linecap="round" stroke-linejoin="round"
                      d="M12 9v3.75m9-.75a9 9 0 11-18 0 9 9 0 0118 0zm-9 3.75h.008v.008H12v-.008z" />
                  </svg>
                  <p class="mt-4 font-semibold text-gray-900">No contexts found</p>
                  <p class="mt-2 text-gray-500">No available contexts found for this search term. Please try again.</p>
                </div>

                <!-- Results, show/hide based on command palette state -->
                <ul v-else class="transform-gpu gap-y-3 flex flex-col py-1" id="options" role="listbox">
                  <!-- Active: "bg-gray-100" -->
                  <li v-for="(context, index) in userContexts" :key="`${context?.name}-${index}`"
                    :class="['group relative flex border rounded-xl px-3 py-5 cursor-pointer hover:border-gray-400 duration-200 focus:outline-none focus:ring-2 focus:ring-black', isCurrentContext(context?.id) ? 'border-black' : 'border-gray-200']"
                    :id="`option-${index}`" role="option" tabindex="-1">
                    <div
                      class="w-10 h-10 rounded-full flex-none bg-gray-500 overflow-hidden relative border border-gray-300 ring-white ring-2">
                      <img v-if="context?.logo" class="w-full h-full object-cover"
                        :src="context?.logo ? context?.logo : null" alt="">
                      <span v-else
                        class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 font-semibold text-2xl text-white origin-center">{{
                        $filters.uppercase(context?.name?.slice(0, 1)) }}</span>
                    </div>
                    <div class="ml-4 flex-auto">
                      <!-- Active: "text-gray-900", Not Active: "text-gray-700" -->
                      <p class="text-sm font-medium text-gray-700">{{ context?.name }}</p>
                      <!-- Active: "text-gray-700", Not Active: "text-gray-500" -->
                      <p class="text-sm text-gray-400">{{ $filters.snakeCaseToTitleCase(context?.context) }}</p>
                    </div>
                    <FormKit
                      name="context"
                      :disabled="true"
                      :value="isCurrentContext(context?.id)"
                      :options="[1]"
                      label=""
                      outer-class="$remove:grow"
                      decorator-class="$remove:mr-1.5"
                      type="radio"
                      decorator-icon="circle"
                    >
                      <template #label></template>
                    </FormKit>
                    <!-- <GenproxRadio class="pointer-events-none"
                      :inputAttrs="{ checked: isCurrentContext(context?.id), disabled: true }" /> -->
                  </li>
                </ul>
              </div>
              <div @click.stop class="bg-white p-4 sm:flex sm:flex-row-reverse sm:px-6 sticky left-0 bottom-0 w-full">
                <GenproxButton size="big" class="w-full" iconPosition="left" variant="black" :icon="userPlus">Add new
                  entity</GenproxButton>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script lang="ts">
import { Component, Vue, Prop, toNative } from 'vue-facing-decorator'
import { XMark, UserPlus, MagnifyingGlass } from '@/components/icons/icons'
import GenproxButton from '@/components/ui/GenproxButton.vue'
import GenproxRadio from '@/components/ui/GenproxRadio.vue'
import GenproxInput from '@/components/ui/GenproxInput.vue'
import { DialogPanel, Dialog, TransitionRoot, TransitionChild } from '@headlessui/vue'

@Component({
  components: { XMark, MagnifyingGlass, GenproxButton, GenproxRadio, GenproxInput, DialogPanel, Dialog, TransitionRoot, TransitionChild }
})
export class ContextsModal extends Vue {
  @Prop({ default: false }) open: boolean
  @Prop({ default: null }) userData: any
  magnifyingGlass: any = MagnifyingGlass
  userPlus: any = UserPlus

  showContent: boolean = false

  /**
   * Computed property that contains information about user available contexts
   * @public
   */
  get userContexts() {
    return this.$store.getters['genprox/userContexts']
  }

  /**
   * Computed property that contains information about current context
   * @public
   */
  get currentContext() {
    const context = this.$store.getters['genprox/userContexts']?.find((context: any) => context?.id === this.userData?.context?.id)

    return context
  }

  /**
   * Function that is used to check if provided context id is id of an current context id
   * @public
   */
  isCurrentContext(id: string): boolean {
    return this.currentContext?.id === id
  }
}

export default toNative(ContextsModal)
</script>

<docs>
```html
<ContextsModal />
```
</docs>

<style lang="postcss" scoped>
.contexts-modal {

  .contexts-modal__container {
    @apply overflow-y-auto;

    max-height: calc(100vh - 6rem);
    width: calc(100vw - 3rem);
    
    @media (min-width: theme('screens.lg')) {
      width: 870px;
    }
  }
}
</style>