<template>
  <div class="home">
    <h1 class="text-3xl text-black leading-16 mb-2">Welcome to Fundequate!</h1>
    <p class="text-md text-gray-400 leading-16">Reporting and Transactional Platform for Investors, Funds and Startups</p>
    <div class="w-48 h-screen mt-16 mb-16 bg-red-100"></div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, toNative } from 'vue-facing-decorator'

@Component({
  components: {}
})
export class DarekPlatform extends Vue {}

export default toNative(DarekPlatform)
</script>
