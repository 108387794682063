<template>
  <div class="reset-password-form">
    <template v-if="!isRequestSent">
      <AuthModuleTitle title="Reset your password">
        Enter your email and we'll send you a link to reset your password.
      </AuthModuleTitle>
      <div class="mt-10">
        <FormKit type="form" id="sendResetPasswordRequest" :incomplete-message="false" form-class="space-y-6" :actions="false" @submit="sendResetPasswordRequest">
          <FormKit 
            label="Email address"
            v-model="email"
            type="email"
            name="email"
            id="email"
            validation="required|email"
          />
          <div>
            <GenproxButton class="w-full" :isLoading="isLoading" type="submit">Reset password</GenproxButton>
          </div>
        </FormKit>
        <div class="mt-8">
          <GenproxDivider>or</GenproxDivider>
          <div class="mt-6 block">
            <GenproxButton class="w-full" variant="white" :outline="true" @click="$router.push('/auth/login')">Sign in
            </GenproxButton>
          </div>
        </div>
      </div>
    </template>
    <template v-else>
      <div class="mt-8 text-center">
        <div class="w-16 h-16 text-green-500 mb-6 mx-auto">
          <CheckCircle class="w-full h-full object-contain" />
        </div>
        <p class="text-2xl font-bold leading-9 tracking-tight text-gray-900">Check your email</p>
        <p class="text-sm leading-6 text-gray-500">Your password reset has been sent.</p>
      </div>
    </template>
  </div>
</template>

<script lang="ts">
import { Component, Setup, Vue, toNative } from 'vue-facing-decorator'
import GenproxLink from '@/components/ui/GenproxLink.vue'
import GenproxInput from '@/components/ui/GenproxInput.vue'
import GenproxDivider from '@/components/ui/GenproxDivider.vue'
import GenproxButton from '@/components/ui/GenproxButton.vue'
import GenproxCheckbox from '@/components/ui/GenproxCheckbox.vue'
import AuthModuleTitle from '@/partials/auth/AuthModuleTitle.vue'
import { CheckCircle } from '@/components/icons/icons'

import { useVuelidate } from '@vuelidate/core'
import { required, email } from '@vuelidate/validators'

@Component({
  components: { AuthModuleTitle, GenproxLink, GenproxInput, GenproxDivider, GenproxButton, GenproxCheckbox, CheckCircle }
})
export class RemindPassword extends Vue {
  isRequestSent: boolean = false
  isLoading: boolean = false
  email: string = null

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  @Setup((props, ctx) => useVuelidate())
  v$: any

  /**
   * Function used when form is submitted
   * @public
   * @param dispatch{/auth/sendRemindPasswordRequest}
   * @param payload{email}
   */
  async sendResetPasswordRequest(): Promise<void> {
    this.v$?.$touch()

    if (!this.v$?.$error) {
      this.isLoading = true
      try {
        await this.$store.dispatch('auth/sendRemindPasswordRequest', this.email)
        this.isRequestSent = true
        this.email = null
      } catch (e) {
        this.$notify({
          duration: 7500,
          type: 'error',
          title: 'Error',
          text: 'There was an error. Are you sure that you provided a correct email address?'
        })
      }
      this.isLoading = false
    }
  }

  validations(): Record<string, unknown> {
    return {
      email: { required, email }
    }
  }
}

export default toNative(RemindPassword)
</script>

<docs>
```html
Validations:
1. email
  - required
  - email
```
</docs>