<!--
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
-->
<template>
  <!--
    This example requires updating your template:

    ```
    <html class="h-full bg-white">
    <body class="h-full">
    ```
  -->
  <div>
    <TransitionRoot as="template" :show="sidebarOpen">
      <Dialog as="div" class="relative z-50 lg:hidden" @close="sidebarOpen = false">
        <TransitionChild as="template" enter="transition-opacity ease-linear duration-300" enter-from="opacity-0"
          enter-to="opacity-100" leave="transition-opacity ease-linear duration-300" leave-from="opacity-100"
          leave-to="opacity-0">
          <div class="fixed inset-0 bg-gray-900/80" />
        </TransitionChild>

        <div class="fixed inset-0 flex">
          <TransitionChild as="template" enter="transition ease-in-out duration-300 transform"
            enter-from="-translate-x-full" enter-to="translate-x-0"
            leave="transition ease-in-out duration-300 transform" leave-from="translate-x-0"
            leave-to="-translate-x-full">
            <DialogPanel class="relative mr-16 flex w-full max-w-xs flex-1">
              <TransitionChild as="template" enter="ease-in-out duration-300" enter-from="opacity-0"
                enter-to="opacity-100" leave="ease-in-out duration-300" leave-from="opacity-100" leave-to="opacity-0">
                <div class="absolute left-full top-0 flex w-16 justify-center pt-5">
                  <button type="button" class="-m-2.5 p-2.5" @click="sidebarOpen = false">
                    <span class="sr-only">Close sidebar</span>
                    <XMarkIcon class="h-6 w-6 text-white" aria-hidden="true" />
                  </button>
                </div>
              </TransitionChild>
              <!-- Sidebar component, swap this element with another sidebar if you like -->
              <div class="flex grow flex-col gap-y-5 overflow-y-auto bg-white pb-4">
                <div
                  class="group flex items-center w-full gap-x-3 rounded-md overflow-hidden text-lg px-4 h-16 font-semibold leading-6 text-black">
                  <img src="@/assets/menu-icon.svg" class="w-10 ml-1 h-10" alt="">
                  <span>Fundequate</span>
                </div>
                <nav class="flex flex-1 flex-col px-6">
                  <ul role="list" class="flex flex-1 flex-col gap-y-7">
                    <li>
                      <ul role="list" class="-mx-2 space-y-1">
                        <li v-for="item in navigation" :key="item.name">
                          <a v-if="!item.children" :href="item.href"
                            :class="[item.current ? 'bg-gray-50' : 'hover:bg-gray-50', 'group flex gap-x-3 rounded-md p-3 text-sm leading-6 font-semibold text-gray-700 overflow-hidden']">
                            <component :is="item.icon" class="h-6 w-6 shrink-0 text-gray-400" aria-hidden="true" />
                            {{ item.name }}
                          </a>
                          <Disclosure as="div" v-else v-slot="{ open }">
                            <DisclosureButton
                              :class="[item.current ? 'bg-gray-50' : 'hover:bg-gray-50', 'flex items-center w-full text-left rounded-md p-3 gap-x-3 text-sm leading-6 font-semibold text-gray-700 overflow-hidden']">
                              <component :is="item.icon" class="h-6 w-6 shrink-0 text-gray-400" aria-hidden="true" />
                              {{ item.name }}
                              <ChevronRightIcon
                                :class="[open ? 'rotate-90 text-gray-500' : 'text-gray-400', 'ml-auto h-5 w-5 shrink-0']"
                                aria-hidden="true" />
                            </DisclosureButton>
                            <DisclosurePanel as="ul" class="mt-1 px-2 overflow-hidden">
                        <li v-for="subItem in item.children" :key="subItem.name">
                          <!-- 44px -->
                          <DisclosureButton as="a" :href="subItem.href"
                            :class="[subItem.current ? 'bg-gray-50' : 'hover:bg-gray-50', 'block text-nowrap rounded-md py-2 pr-2 pl-10 text-sm leading-6 text-gray-700 overflow-hidden']">
                            {{ subItem.name }}</DisclosureButton>
                        </li>
                        </DisclosurePanel>
                        </Disclosure>
                    </li>
                  </ul>
                  </li>
                  <li class="mt-auto">
                    <a href="#" @click.prevent="logout"
                      class="group -mx-2 flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-gray-700 hover:bg-gray-50 hover:text-primary-600">
                      <ArrowLeftOnRectangleIcon class="h-6 w-6 shrink-0 text-gray-400 group-hover:text-primary-600"
                        aria-hidden="true" />
                      Sign out
                    </a>
                  </li>
                  </ul>
                </nav>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </Dialog>
    </TransitionRoot>

    <!-- Static sidebar for desktop -->
    <div
      :class="['hidden transition-all duration-300 lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:flex-col', isExpanded ? 'lg:w-72 lg:max-w-72' : 'lg:w-20 lg:max-w-20']">
      <!-- Sidebar component, swap this element with another sidebar if you like -->
      <div class="flex grow flex-col gap-y-5 overflow-y-auto border-r border-gray-200 bg-white pb-4">
        <div class="absolute top-0 l-0 w-full h-16">
          <div @click="isExpanded = !isExpanded"
            class="absolute z-10 w-6 h-6 rounded-md bg-white border border-gray-200 -right-3 top-1/2 -translate-y-1/2 cursor-pointer hover:bg-gray-50 transition">
            <ArrowUpLeftIcon
              :class="[isExpanded ? 'transform -rotate-90' : 'rotate-90', 'right-1 top-1 transition delay-300 duration-300 pointer-events-none absolute w-2 h-2']" />
            <ArrowDownRightIcon
              :class="[isExpanded ? 'transform -rotate-90' : 'rotate-90', 'left-1 bottom-1 transition delay-300 duration-300 pointer-events-none absolute w-2 h-2']" />
          </div>
        </div>
        <div class="flex h-16 px-4 shrink-0 items-center relative">
          <div
            class="group flex items-center w-full gap-x-3 rounded-md overflow-hidden text-lg font-semibold leading-6 text-black">
            <img src="@/assets/menu-icon.svg" class="w-10 ml-1 h-10" alt="">
            <span>Fundequate</span>
          </div>
        </div>
        <nav class="flex flex-1 flex-col px-6">
          <ul role="list" class="flex flex-1 flex-col gap-y-7">
            <li>
              <ul role="list" class="-mx-2 space-y-1">
                <li v-for="item in navigation" :key="item.name">
                  <a v-if="!item.children" :href="item.href"
                    :class="[item.current ? 'bg-gray-50' : 'hover:bg-gray-50', 'group flex gap-x-3 rounded-md p-3 text-sm leading-6 font-semibold text-gray-700 overflow-hidden']">
                    <component :is="item.icon" class="h-6 w-6 shrink-0 text-gray-400" aria-hidden="true" />
                    {{ item.name }}
                  </a>
                  <Disclosure as="div" v-else v-slot="{ open }">
                    <DisclosureButton
                      :class="[item.current ? 'bg-gray-50' : 'hover:bg-gray-50', 'flex items-center w-full text-left rounded-md p-3 gap-x-3 text-sm leading-6 font-semibold text-gray-700 overflow-hidden']">
                      <component :is="item.icon" class="h-6 w-6 shrink-0 text-gray-400" aria-hidden="true" />
                      {{ item.name }}
                      <ChevronRightIcon
                        :class="[open ? 'rotate-90 text-gray-500' : 'text-gray-400', 'ml-auto h-5 w-5 shrink-0']"
                        aria-hidden="true" />
                    </DisclosureButton>
                    <DisclosurePanel as="ul" class="mt-1 px-2 overflow-hidden">
                <li v-for="subItem in item.children" :key="subItem.name">
                  <!-- 44px -->
                  <DisclosureButton as="a" :href="subItem.href"
                    :class="[subItem.current ? 'bg-gray-50' : 'hover:bg-gray-50', 'block text-nowrap rounded-md py-2 pr-2 pl-10 text-sm leading-6 text-gray-700 overflow-hidden']">
                    {{ subItem.name }}</DisclosureButton>
                </li>
                </DisclosurePanel>
                </Disclosure>
            </li>
          </ul>
          </li>
          <li class="mt-auto">
            <a href="#" @click.prevent="logout"
              class="group -mx-2 flex gap-x-3 rounded-md p-3 text-sm font-semibold overflow-hidden text-nowrap leading-6 text-gray-700 hover:bg-gray-50 hover:text-primary-600">
              <ArrowLeftOnRectangleIcon class="h-6 w-6 shrink-0 text-gray-400 group-hover:text-primary-600"
                aria-hidden="true" />
              Sign out
            </a>
          </li>
          </ul>
        </nav>
      </div>
    </div>

    <div :class="['transition-all duration-300', isExpanded ? 'lg:pl-72' : 'lg:pl-20']">
      <div
        class="sticky top-0 z-40 flex h-16 shrink-0 items-center gap-x-4 border-b border-gray-200 bg-white px-4 shadow-sm sm:gap-x-6 sm:px-6 lg:px-8">
        <button type="button" class="-m-2.5 p-2.5 text-gray-700 lg:hidden" @click="sidebarOpen = true">
          <span class="sr-only">Open sidebar</span>
          <Bars3Icon class="h-6 w-6" aria-hidden="true" />
        </button>

        <!-- Separator -->
        <div class="hidden h-6 w-px bg-gray-200 sm:block lg:hidden" aria-hidden="true" />

        <div class="flex flex-1 gap-x-4 self-stretch lg:gap-x-6">
          <nav class="hidden mr-4 sm:flex" aria-label="Breadcrumb">
            <ol role="list" class="flex items-center space-x-4">
              <li>
                <div>
                  <a href="#" class="flex items-center text-gray-400 hover:text-gray-500">
                    <HomeIcon class="h-5 w-5 flex-shrink-0" aria-hidden="true" />
                    <span class="sr-only">Home</span>
                    <a v-if="!pages?.length" href="#" class="ml-4 font-medium text-primary-600 text-sm">Welcome Page</a>
                  </a>
                </div>
              </li>
              <li v-for="page in pages" :key="page.name">
                <div class="flex items-center">
                  <ChevronRightIcon class="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                  <a :href="page.href"
                    :class="['ml-4 text-sm font-medium', page?.current ? 'text-primary-600' : 'text-gray-500 hover:text-gray-700']"
                    :aria-current="page.current ? 'page' : undefined">{{ page.name }}</a>
                </div>
              </li>
            </ol>
          </nav>
          <div class="flex items-center gap-x-4 lg:gap-x-6 ml-auto">
            <!-- Profile dropdown -->
            <Menu as="div" class="relative">
              <MenuButton class="-m-1.5 flex items-center p-1.5">
                <span class="sr-only">Open user menu</span>
                <div class="h-10 w-10 relative">
                  <div class="h-full w-full bg-gray-500 rounded-full overflow-hidden relative">
                    <img v-if="user?.logo" class="w-full h-full object-cover" :src="user?.logo ? user?.logo : null"
                      alt="" />
                    <span v-else class="leading-[2] absolute inset-0 font-semibold text-xl text-white origin-center">{{
                      $filters.uppercase(user?.firstName?.slice(0, 1))
                      }}</span>
                  </div>
                  <div
                    class="context-box w-6 h-6 absolute -right-2 -bottom-1 bg-gray-500 border border-gray-300 rounded-md overflow-hidden ring-white ring-2">
                    <div
                      class="absolute top-0 left-0 w-full h-full bg-gray-500 duration-200 text-white opacity-0 hover:opacity-100"
                      @click.stop="openContextsModal">
                      <ArrowPathRoundedSquareIcon
                        class="w-4 h-4 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2" />
                    </div>
                    <img v-if="currentContext?.logo" class="w-full h-full object-cover" :src="currentContext?.logo"
                      :alt="currentContext?.name">
                    <span v-else
                      class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 font-semibold text-sm text-white origin-center">{{
                      $filters.uppercase(currentContext?.name?.slice(0, 1)) }}</span>
                  </div>
                </div>
                <span class="hidden lg:flex lg:items-center">
                  <div class="block text-left ml-4 text-sm max-w-fit w-fit overflow-hidden">
                    <div class="font-semibold leading-6 text-gray-900" aria-hidden="true">{{ user?.firstName || "" }} {{ user?.surname || "" }}</div>
                  </div>
                  <ChevronDownIcon class="ml-2 h-5 w-5 text-gray-400" aria-hidden="true" />
                </span>
              </MenuButton>
              <transition enter-active-class="transition ease-out duration-100"
                enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100"
                leave-active-class="transition ease-in duration-75" leave-from-class="transform opacity-100 scale-100"
                leave-to-class="transform opacity-0 scale-95">
                <MenuItems
                  class="absolute right-0 z-10 mt-2.5 w-64 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">

                  <div class="border-b border-solid border-gray-100 mb-2 overflow-hidden pl-4 pr-16 pt-2 pb-4 relative">
                    <p class="text-sm text-gray-400">Currently logged in at:</p>
                    <p
                      class="text-md text-black font-semibold -mt-1 whitespace-nowrap overflow-hidden overflow-ellipsis">
                      High Capital Management sp. z o.o.</p>
                    <div @click="openContextsModal"
                      class="absolute right-4 top-1/2 transform -translate-y-1/2 w-6 h-6 cursor-pointer hover:text-primary-600">
                      <ArrowPathRoundedSquareIcon class="w-full h-full" />
                    </div>
                  </div>

                  <MenuItem v-slot="{ active }">
                  <a href="#" :class="[
                        active ? 'bg-gray-50' : '',
                        'block px-4 py-2 text-sm leading-6 text-gray-900',
                      ]">Your profile</a>
                  </MenuItem>
                  <MenuItem v-slot="{ active }">
                  <a href="#" @click.prevent="logout" :class="[
                        active ? 'bg-gray-50' : '',
                        'block px-4 py-2 text-sm leading-6 text-gray-900',
                      ]">Sign out</a>
                  </MenuItem>
                </MenuItems>
              </transition>
            </Menu>
          </div>
        </div>
      </div>
      <ContextsModal :open="showContextsModal" :userData="userData" @click.prevent @close="showContextsModal = false" />

      <main class="py-10">
        <div class="px-4 sm:px-6 lg:px-8">
          <router-view></router-view>
        </div>
      </main>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, toNative } from "vue-facing-decorator";
import ContextsModal from "@/components/ContextsModal.vue";
import {
  Dialog,
  DialogPanel,
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  TransitionChild,
  TransitionRoot,
  Disclosure, 
  DisclosureButton,
  DisclosurePanel
} from "@headlessui/vue";
import {
  Bars3Icon,
  BellIcon,
  CalendarIcon,
  ChartPieIcon,
  Cog6ToothIcon,
  ArrowLeftOnRectangleIcon,
  ArrowPathRoundedSquareIcon,
  DocumentDuplicateIcon,
  FolderIcon,
  HomeIcon,
  UsersIcon,
  XMarkIcon,
  ChevronRightIcon,
  ArrowUpLeftIcon,
  ArrowDownRightIcon,
} from "@heroicons/vue/24/outline";
import { ChevronDownIcon, MagnifyingGlassIcon } from "@heroicons/vue/20/solid";

@Component({
  components: {
    ContextsModal,
    Dialog,
    DialogPanel,
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    TransitionChild,
    TransitionRoot,
    Bars3Icon,
    BellIcon,
    CalendarIcon,
    ChartPieIcon,
    Cog6ToothIcon,
    ArrowLeftOnRectangleIcon,
    ArrowPathRoundedSquareIcon,
    DocumentDuplicateIcon,
    ArrowUpLeftIcon,
    ArrowDownRightIcon,
    FolderIcon,
    HomeIcon,
    UsersIcon,
    XMarkIcon,
    ChevronDownIcon,
    MagnifyingGlassIcon,
    ChevronRightIcon,
    Disclosure,
    DisclosureButton,
    DisclosurePanel
  },
})
export class GenproxModule extends Vue {
  showContextsModal: boolean = false;
  sidebarOpen: boolean = false;
  isExpanded: boolean = true;

  navigation: any[] = [
    { name: 'Dashboard', href: '#', icon: HomeIcon, current: true },
    {
      name: 'Teams',
      icon: UsersIcon,
      current: false,
      children: [
        { name: 'Engineering', href: '#' },
        { name: 'Human Resources', href: '#' },
        { name: 'Customer Success', href: '#' },
      ],
    },
    {
      name: 'Projects',
      icon: FolderIcon,
      current: false,
      children: [
        { name: 'GraphQL API', href: '#' },
        { name: 'iOS App', href: '#' },
        { name: 'Android App', href: '#' },
        { name: 'New Customer Portal', href: '#' },
      ],
    },
    { name: 'Calendar', href: '#', icon: CalendarIcon, current: false },
    { name: 'Documents', href: '#', icon: DocumentDuplicateIcon, current: false },
    { name: 'Reports', href: '#', icon: ChartPieIcon, current: false },
  ];
  pages: any[] = [
    // { name: 'Fund', href: '#', current: true },
    // { name: 'Capital Rise', href: '#', current: true },
  ]

  isReady: boolean = false;

  /**
   * Computed property that contains logged in user data
   * @public
   */
  get userData() {
    return this.$store.getters["genprox/activeUserData"];
  }

  /**
   * Computed property that contains logged in user information
   * @public
   */
  get user() {
    return this.userData?.user;
  }

  /**
   * Computed property that contains information about current context
   * @public
   */
  get currentContext() {
    const context = this.$store.getters['genprox/userContexts']?.find((context: any) => context?.id === this.userData?.context?.id)

    return context
  }

  /**
   * Open contexts modal method handler
   * @public
   */
  openContextsModal(): void {
    this.showContextsModal = true
  }

  /**
   * Function that is initialising entire platform
   * @public
   */
  async getAppSetupData(): Promise<void> {
    if (
      !localStorage.getItem("apiKey") ||
      !localStorage.getItem("refreshToken")
    ) {
      this.$router.push("/auth/login");
    }

    await this.$store.dispatch("genprox/getActiveUserData");
    await this.$store.dispatch("genprox/getUserContexts");

    const currentContext: any = this.$store.getters["auth/jwtTokenData"];

    if (currentContext?.context_type === "advisor-panel") {
      const contexts: any[] = this.$store.getters["genprox/userContexts"];
      const correctContext: any = contexts.find(
        (el: any) => el.context !== "advisor-panel"
      );
      if (correctContext) {
        await this.$store.dispatch("genprox/setUserContext", {
          id: correctContext.id,
        });
      }
    }

    await Promise.all([
      this.$store.dispatch("genprox/getAccessModules"),
      this.$store.dispatch("genprox/getMenus"),
      this.$store.dispatch("genprox/getCMSWelcome"),
    ]);

    try {
      await this.$store.dispatch("genprox/getUserPackages");
    } catch (err) {
      console.log(err);
    }
  }

  /**
   * Function that is used to log out the user
   * @public
   */
  async logout(): Promise<void> {
    await this.$store.dispatch('auth/logout')
  }

  async beforeMount() {
    await this.getAppSetupData();
    await this.performMenuGuardCheck();

    this.isReady = true;
  }

  private async performMenuGuardCheck(): Promise<void> {
    if (this.$route.query.redirect) {
      try {
        await this.$router.push({ path: this.$route.query.redirect as string });
      } catch (e) {
        const query = Object.assign({}, this.$route.query);
        delete query.redirect;
        await this.$router.replace({ query });
      }
    }
  }
}

export default toNative(GenproxModule);
</script>
