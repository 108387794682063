<template>
  <div :class="['gn-checkbox', hasError ? 'error' : '']">
    <div class="flex items-start">
      <input type="checkbox" v-bind="{...$props?.inputAttrs}" v-model="modelValue" :id="name" :name="name"
        @input="handleInput(inputValue)" class="gn-checkbox__input" />
      <div class="gn-checkbox__input-block">
        <svg class="gn-checkbox__icon" version="1.1" viewBox="0 0 17 12" xmlns="http://www.w3.org/2000/svg">
          <g fill="none" fill-rule="evenodd">
            <g transform="translate(-9 -11)" fill="currentColor" fill-rule="nonzero">
              <path
                d="m25.576 11.414c0.56558 0.55188 0.56558 1.4439 0 1.9961l-9.404 9.176c-0.28213 0.27529-0.65247 0.41385-1.0228 0.41385-0.37034 0-0.74068-0.13855-1.0228-0.41385l-4.7019-4.588c-0.56584-0.55188-0.56584-1.4442 0-1.9961 0.56558-0.55214 1.4798-0.55214 2.0456 0l3.679 3.5899 8.3812-8.1779c0.56558-0.55214 1.4798-0.55214 2.0456 0z" />
            </g>
          </g>
        </svg>
      </div>
      <div class="w-full block">
        <label :for="name" class="select-none text-sm mt-0.5 text-gray-700 inline-block cursor-pointer">
          <!-- @slot Checkbox label content here -->
          <slot></slot>
        </label>
        <GenproxError :validation="validation" :id="`${name}-error`" />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Emit, Prop, toNative } from 'vue-facing-decorator'
import GenproxError from '@/components/ui/GenproxError.vue'

@Component({
  components: { GenproxError }
})
export class GenproxCheckbox extends Vue {
  @Prop({ default: null }) validation: Record<string, unknown>
  @Prop({ default: null }) inputAttrs: Record<string, unknown>
  @Prop({ default: null }) name: string
  @Prop() inputValue!: boolean

  modelValue: any = null

  @Emit('input')
  handleInput(inputValue: any) {
    this.modelValue = !inputValue
    return this.modelValue
  }

  /**
   * Has error computed property
   * @public
   */
  get hasError(): boolean {
    return !!(this.validation?.$error)
  }

  mounted() {
    this.modelValue = this.inputValue
  }
}

export default toNative(GenproxCheckbox)
</script>

<style lang="postcss" scoped>
.gn-checkbox {
  @apply relative block;

  .gn-checkbox__input {
    @apply opacity-0 absolute h-4 w-4 cursor-pointer;

    &:focus-within {
      & + div {
        @apply ring-2 ring-offset-1 ring-gray-800;
      }
    }

    &:checked {
      & + div {
        svg {
          @apply block;
        }
      }
    }

    &[disabled] {
      & + div {
        @apply bg-gray-100;
      }
    }
  }

  .gn-checkbox__input-block {
    @apply bg-white border rounded border-gray-300 w-4 h-4 flex flex-shrink-0 justify-center mt-1 items-center mr-2 cursor-pointer;
  }

  .gn-checkbox__icon {
    @apply object-contain hidden w-2 h-2 text-gray-800 pointer-events-none;
  }

  &.error {
    .gn-checkbox__input-block {
      @apply border-red-600;
    }
    .gn-checkbox__icon {
      @apply text-red-600;
    }
  }
}
</style>

<docs>
```html
<GenproxCheckbox>Checkbox Label</GenproxCheckbox>
```
</docs>