import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "reset-password-form" }
const _hoisted_2 = { class: "mt-10" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AuthModuleTitle = _resolveComponent("AuthModuleTitle")!
  const _component_GenproxInput = _resolveComponent("GenproxInput")!
  const _component_GenproxButton = _resolveComponent("GenproxButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_AuthModuleTitle, { title: "Activate your account" }, {
      default: _withCtx(() => [
        _createTextVNode(" Set your password and start using our platform. ")
      ], undefined, true),
      _: 1
    }),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", null, [
        _createElementVNode("form", {
          action: "#",
          method: "POST",
          class: "space-y-6",
          onSubmit: _cache[2] || (_cache[2] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.activateAccount && _ctx.activateAccount(...args)), ["prevent"]))
        }, [
          _createVNode(_component_GenproxInput, {
            modelValue: _ctx.password,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.password) = $event)),
            inputAttrs: { type: 'password', required: true },
            validation: _ctx.v$?.password,
            id: "password",
            label: "Password"
          }, null, 8, ["modelValue", "validation"]),
          _createVNode(_component_GenproxInput, {
            modelValue: _ctx.repeatPassword,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.repeatPassword) = $event)),
            inputAttrs: { type: 'password' },
            validation: _ctx.v$?.repeatPassword,
            id: "repeatPassword",
            label: "Repeat password"
          }, null, 8, ["modelValue", "validation"]),
          _createElementVNode("div", null, [
            _createVNode(_component_GenproxButton, {
              class: "w-full",
              isLoading: _ctx.isLoading,
              type: "submit"
            }, {
              default: _withCtx(() => [
                _createTextVNode("Activate account")
              ], undefined, true),
              _: 1
            }, 8, ["isLoading"])
          ])
        ], 32)
      ])
    ])
  ]))
}