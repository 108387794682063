/* eslint-disable @typescript-eslint/no-unused-vars */
import Vue from 'vue'
import { ExtendedStoreOptions, RootState } from '@/store/types'
import { ActionTree, GetterTree, Module, MutationTree } from "vuex";
import rootStore from '@/store/rootStore'
import { REDIRECTED_PATH, BACKEND_API_URL, BACKEND_BASE_URL } from "@/shared/consts"
import Utils from '@/shared/utils/utils'
import router from '@/router'

import axios from '@/plugins/axios'

import {
  ActiveAccountPayloadDTO,
  AuthState,
  InvestmentClientRegisterAuthDTO,
  LiveDemoFormDataDTO,
  LoginCredentialsDTO,
  LoginWithTwoFactorDTO,
  RegisterDTO,
  ResetPasswordDataDTO,
} from '@/store/modules/auth/types'

export const authStateNamespace = "auth";
export const state: AuthState = {
  authToken: null,
  refreshToken: getRefreshTokenFromLS(),
  apiKey: getApiKeyFromLS(),
  jwtToken: null,
  jwtTokenData: Utils.parseJwt(getApiKeyFromLS()),
  investedFunds: [],
  viewedFundId: "",
  investedCompanies: [],
  viewedCompanyId: "",
  twoFactorCode: "",
  requireTwoFactor: false,
};

export const getters: GetterTree<AuthState, RootState> = {
  apiKey(state): string | null {
    return state.apiKey;
  },
  jwtToken(state): any {
    return state.jwtToken;
  },
  jwtTokenData(state): any {
    return state.jwtTokenData;
  },
  getInvestedFunds(state): any {
    return state.investedFunds;
  },
  getViewedFundId(state): string {
    return state.viewedFundId;
  },
  getInvestedCompanies(state): any {
    return state.investedCompanies;
  },
  getViewedCompanyId(state): string {
    return state.viewedCompanyId;
  },
  requireTwoFactor(state): boolean {
    return state.requireTwoFactor;
  },
  refreshToken(state): string | null {
    return state.refreshToken;
  },
  refreshTokenData(state): any {
    if (state.refreshToken) {
      return Utils.parseJwt(state.refreshToken);
    }

    return null;
  },
};

export const mutations: MutationTree<AuthState> = {
  setApiKey(state, payload: string): void {
    state.apiKey = payload;
    localStorage.setItem("apiKey", payload);
    if (payload) {
      axios.defaults.headers.common["authorization"] = "Bearer " + payload;
    }
    state.jwtTokenData = Utils.parseJwt(payload);
  },
  setRefreshToken(state, payload: any) {
    localStorage.setItem("refreshToken", payload);
    state.refreshToken = payload;
  },
  setAuthToken(state, payload: string): void {
    state.authToken = payload;
  },
  removeApiKey(state): void {
    localStorage.removeItem("apiKey");
    localStorage.removeItem("refreshToken");
    delete axios.defaults.headers.common["authorization"];
    state.refreshToken = null;
    state.apiKey = null;
  },
  setInvestedFunds(state, payload: Array<any>) {
    state.investedFunds = payload;
  },
  setViewedFund(state, id: string) {
    state.viewedFundId = id;
  },
  setInvestedCompanies(state, payload: Array<any>) {
    state.investedCompanies = payload;
  },
  setViewedCompany(state, id: string) {
    state.viewedCompanyId = id;
  },
  setTwoFactorCode(state, code: string) {
    state.twoFactorCode = code;
  },
  setRequireTwoFactor(state, payload: boolean) {
    state.requireTwoFactor = payload;
  },
};

export const actions: ActionTree<AuthState, RootState> = {
  async changeViewedFund({ commit }, id: string) {
    await axios.patch(`${BACKEND_API_URL}/auth/viewed/fund`, { id });
    commit("setViewedFund", id);
  },
  async changeViewedCompany({ commit }, id: string) {
    await axios.patch(`${BACKEND_API_URL}/auth/viewed/company`, { id });
    commit("setViewedCompany", id);
  },
  async getApiKey({ state, commit }): Promise<boolean> {
    try {
      const { data } = await axios.get(
        `${BACKEND_BASE_URL}/auth/jwt/${state.authToken}`
      );
      commit("setApiKey", data);
      return true;
    } catch (error) {
      commit("removeApiKey");
      return false;
    }
  },
  async login(
    { commit },
    payload: LoginCredentialsDTO
  ): Promise<void> {
    const userCookie = Utils.getCookie(payload.email);
    if (userCookie) {
      const tokenData = Utils.parseJwt(userCookie);
      const dateToCompare = new Date();
      dateToCompare.setTime(dateToCompare.getTime() + tokenData.exp);

      if (new Date().getTime() < dateToCompare.getTime()) {
        payload["remember2faToken"] = userCookie;
      }
    }

    const response = await axios.post(
      `${BACKEND_BASE_URL}/auth/login`,
      payload
    );

    const tokenType = response.data.type;

    if (tokenType === "auth-token") {
      const token = response.data.accessToken;
      commit("setApiKey", token);

      if (response?.data?.refreshToken) {
        commit("setRefreshToken", response?.data.refreshToken);
      }

      // await rootStore.dispatch("genprox/getUserContexts");

      // const currentContext = getters.jwtTokenData;
      // const contexts = rootStore.getters["genprox/userContexts"];

      // if (currentContext.context_type === "advisor-panel") {
      //   const correctContext = contexts.find(
      //     (el: any) => el.context !== "advisor-panel"
      //   );

      //   if (correctContext) {
          // await rootStore.dispatch("genprox/setUserContext", {
      //       id: correctContext.id,
      //     });
      //   } else {
      //     // REDIRECT TO WL
      //     const brokerPanelData = contexts[0];
      //     const redirectToken = await dispatch(
      //       "generateChangedLoggedContext",
      //       currentContext?.context_id
      //     );

      //     // (this as ExtendedStoreOptions).$notify({
      //     //   duration: 2500,
      //     //   type: "success",
      //     //   title: "Success",
      //     //   text: "You'll be redirected to your broker panel in a couple of seconds.",
      //     // });

      //     setTimeout(() => {
      //       window.location.href = `${brokerPanelData?.www}/login-with-redirect-token?token=${redirectToken}`;
      //     }, 3000);

      //     return;
      //   }
      // }

      if (!localStorage.getItem(REDIRECTED_PATH)) {
        await router.push({ name: 'welcome' });
      } else {
        // const redirectedPathContext = new URL(
        //   window.location.origin + localStorage.getItem(REDIRECTED_PATH)
        // )?.searchParams?.get("contextOwnerId");

        // if (redirectedPathContext) {
        //   if (contexts?.find((el: any) => el.id === redirectedPathContext)) {
        //     router.push({ path: localStorage.getItem(REDIRECTED_PATH) });
        //   } else {
        //     router.push({ name: "welcome" });
        //   }
        // } else {
          await router.push({ path: localStorage.getItem(REDIRECTED_PATH) });
        // }
      }
      localStorage.removeItem(REDIRECTED_PATH);

      (this as ExtendedStoreOptions).$notify({
        type: "success",
        title: "Success",
        text: "You've been successfully signed in.",
      });

    } else {
      const token = response.data.token;
      console.log('redirect to confirm login page', token, router)
      router.push({ name: "confirm-login", params: { token } });
    }
    return;
  },
  async sendRemindPasswordRequest({ commit }, email: string): Promise<any> {
    return axios.post(`${BACKEND_BASE_URL}/auth/password/send-remind-request`, {
      email,
    });
  },
  async resetPassword({ commit }, payload: ResetPasswordDataDTO): Promise<any> {
    return axios.post(`${BACKEND_BASE_URL}/auth/password/set`, payload);
  },
  async activateAccountFromEmail(
    { commit },
    payload: ActiveAccountPayloadDTO
  ): Promise<any> {
    return axios.post(`${BACKEND_BASE_URL}/auth/activate-from-email`, payload);
  },
  async activateAccount({ commit }, payload: { token: string }): Promise<any> {
    return axios.post(`${BACKEND_BASE_URL}/auth/activate`, payload);
  },
  async requestDemo({ commit }, payload: LiveDemoFormDataDTO): Promise<any> {
    return axios.post(`${BACKEND_BASE_URL}/request-demo`, payload);
  },
  async impersonate({ commit }, payload) {
    const response = await axios.post(
      `${BACKEND_BASE_URL}/auth/impersonate`,
      payload
    );

    if (response?.data?.accessToken) {
      commit("setApiKey", response.data.accessToken);
    }

    if (response?.data?.refreshToken) {
      commit("setRefreshToken", response.data.refreshToken);
    }

    localStorage.removeItem(REDIRECTED_PATH);
  },
  async logout({ commit }): Promise<any> {
    await axios.post(`${BACKEND_API_URL}/auth/logout`)
    commit("removeApiKey");
    router.push({ name: "login" });
  },
  async getUserByToken({ commit }, token: string) {
    return axios.get<InvestmentClientRegisterAuthDTO>(
      `${BACKEND_BASE_URL}/auth/investment-client/${token}`
    );
  },
  async register({ commit }, data: RegisterDTO): Promise<any> {
    //TODO: Add types
    return axios.post(`${BACKEND_BASE_URL}/auth/register`, data);
  },
  async getViewedFund({ commit }): Promise<any> {
    const { data } = await axios.get(`${BACKEND_API_URL}/auth/viewed/fund`);
    commit("setViewedFund", data);
    return data;
  },
  async getInvestedFunds({ commit }): Promise<any> {
    const { data } = await axios.get(
      `${BACKEND_API_URL}/structure/invested-funds`
    );
    commit("setInvestedFunds", data);
    return data;
  },
  async getViewedCompany({ commit }): Promise<any> {
    const { data } = await axios.get(`${BACKEND_API_URL}/auth/viewed/company`);
    commit("setViewedCompany", data);
    return data;
  },
  async getInvestedCompanies({ commit }): Promise<any> {
    const { data } = await axios.get(
      `${BACKEND_API_URL}/structure/invested-companies`
    );
    commit("setInvestedCompanies", data);
    return data;
  },
  async getTwoFactorCode({ commit }): Promise<any> {
    const { data } = await axios.get(
      `${BACKEND_API_URL}/user/two-factor-authentication/qr-code`
    );
    commit("setTwoFactorCode", data);
    return data;
  },
  async enableTwoFactor({ commit }, code: string): Promise<any> {
    const resp = await axios.patch(
      `${BACKEND_API_URL}/user/two-factor-authentication/enable`,
      { confirmationCode: code }
    );

    return resp;
  },
  async disableTwoFactor(): Promise<any> {
    const { data } = await axios.patch(
      `${BACKEND_API_URL}/user/two-factor-authentication/disable`
    );

    return data;
  },
  async sendToken({ commit }, email) {
    const { data } = await axios.post(
      `${BACKEND_BASE_URL}/auth/activate/send-token`,
      { email }
    );

    return data;
  },
  async loginWithTwoFactor(
    { commit, getters, dispatch },
    payload: LoginWithTwoFactorDTO
  ): Promise<any> {
    const { data } = await axios.post(
      `${BACKEND_BASE_URL}/auth/login-with-two-factor`,
      payload
    );

    const tokenType = data?.type;

    if (tokenType === "auth-token") {
      const token = data?.accessToken;
      commit("setApiKey", token);

      if (data?.refreshToken) {
        commit("setRefreshToken", data.refreshToken);
      }

      if (data?.remember2faToken) {
        const remember2faTokenData = Utils.parseJwt(data.remember2faToken);
        Utils.setCookie(
          remember2faTokenData.username,
          data.remember2faToken.toString(),
          remember2faTokenData.exp
        );
      }

      // await rootStore.dispatch("genprox/getUserContexts");

      // const currentContext = getters.jwtTokenData;
      // const contexts = rootStore.getters["genprox/userContexts"];

      // if (currentContext.context_type === "advisor-panel") {
      //   const correctContext = contexts.find(
      //     (el: any) => el.context !== "advisor-panel"
      //   );

      //   if (correctContext) {
          // await rootStore.dispatch("genprox/setUserContext", {
      //       id: correctContext.id,
      //     });
      //   } else {
      //     // REDIRECT TO WL
      //     const brokerPanelData = contexts[0];
      //     const redirectToken = await dispatch(
      //       "generateChangedLoggedContext",
      //       currentContext?.context_id
      //     );

      //     // (this as ExtendedStoreOptions).$notify({
      //     //   duration: 2500,
      //     //   type: "success",
      //     //   title: "Success",
      //     //   text: "You'll be redirected to your broker panel in a couple of seconds.",
      //     // });

      //     setTimeout(() => {
      //       window.location.href = `${brokerPanelData?.www}/login-with-redirect-token?token=${redirectToken}`;
      //     }, 3000);

      //     return;
      //   }
      // }

      if (!localStorage.getItem(REDIRECTED_PATH)) {
        await router.push({ name: "welcome" });
      } else {
        await router.push({ path: localStorage.getItem(REDIRECTED_PATH) });
      }
      (this as ExtendedStoreOptions).$notify({
        type: "success",
        title: "Success",
        text: "You've been successfully signed in.",
      });
    }

    return data;
  },
  async generateChangedLoggedContext({ state }, contextId: string) {
    const { data } = await axios.patch(
      `${BACKEND_API_URL}/auth/change-context/generate-token`,
      { contextId: contextId }
    );

    return data;
  },
};

function getApiKeyFromLS(): string | null {
  return localStorage.getItem("apiKey");
}

function getRefreshTokenFromLS(): string | null {
  if (localStorage.getItem("refreshToken")) {
    return localStorage.getItem("refreshToken");
  }

  return null;
}

export const auth: Module<AuthState, RootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
