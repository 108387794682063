<template>
  <transition 
    name="fade"
    enter-active-class="ease-out duration-300" 
    enter-class="opacity-0" 
    enter-to-class="opacity-100" 
    leave-active-class="ease-in duration-200" 
    leave-class="opacity-100" 
    leave-to-class="opacity-0" 
    @after-enter="showContent = true" 
    appear
  >
    <div class="demo-modal relative z-50" aria-labelledby="modal-title" role="dialog" aria-modal="true" @click="$emit('close')">
      <div class="demo-modal__bg fixed inset-0 z-50 bg-gray-500 bg-opacity-75 transition-opacity"></div> 
      <div class="fixed inset-0 z-50 w-screen overflow-y-auto">
        <div class="flex min-h-full items-center justify-center p-4 text-center sm:p-0">\
          <transition 
            name="fade" 
            enter-active-class="ease-out duration-300"
            enter-class="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" 
            enter-to-class="opacity-100 translate-y-0 sm:scale-100" 
            leave-active-class="ease-in duration-500"
            leave-class="opacity-100 translate-y-0 sm:scale-100"
            leave-to-class="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            appear
          >
            <div
              v-if="showContent"
              @click.stop
              class="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 demo-modal__container">
              <div class="bg-white px-4 pb-4 pt-5 sm:p-6">
                <div class="absolute right-0 top-0 block pr-4 pt-4">
                  <button type="button" @click="$emit('close')"
                    class="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-gray-800 focus:ring-offset-2">
                    <span class="sr-only">Close</span>
                    <XMark class="h-6 w-6" />
                  </button>
                </div>
                <div class="text-left">
                  <h3 class="text-base font-semibold leading-6 text-gray-900 pb-3 pr-6 border-b border-gray-200"
                    id="modal-title">Request a demo</h3>
                  <div class="mt-5">
                    <iframe ref="iframeEl" src="https://genprox.pipedrive.com/scheduler/KkRaMRIR/demo-fundequate"
                      title="Request a demo" class="w-full demo-modal__iframe" frameborder="0"></iframe>
                  </div>
                </div>
              </div>
            </div>
          </transition>
        </div>
      </div>
    </div>
  </transition>
</template>

<script lang="ts">
import { Component, Vue, toNative } from 'vue-facing-decorator'
import { XMark } from '@/components/icons/icons';

@Component({
  components: { XMark }
})
export class DemoModal extends Vue {
  showContent: boolean = false
}

export default toNative(DemoModal)
</script>

<docs>
```html
<DemoModal />
```
</docs>

<style lang="postcss" scoped>
.demo-modal {

  .demo-modal__iframe {
    max-height: calc(100vh - 200px);
    height: calc(100vh - 200px) !important;
  }

  .demo-modal__container {
    width: calc(100vw - 3rem);
    
    @media (min-width: theme('screens.lg')) {
      width: 870px;
    }
  }
}
</style>