<template>
  <div class="genprox-error">
    <div class="mt-2 text-sm text-red-600" v-if="validation && validation?.$error && !useCustomError">
      <div v-if="validation.required === false">
        This field is required
      </div>
      <div v-if="validation.minLength === false">
        Minimum length is {{ validation.$params.minLength.min }}
      </div>
      <div v-if="validation.maxLength === false">
        Maximum length is {{ validation.$params.maxLength.max }}
      </div>
      <div v-if="validation.email === false">
        This field must be valid email
      </div>
      <div v-if="validation.strongPassword === false">
        Password is too weak. It must be at least 8 characters long and include one uppercase, one lowercase letter and
        number or special character.
      </div>
      <div v-if="validation.sameAsPassword === false">
        Passwords must be same
      </div>
      <div v-if="validation.minValue === false">
        Minimum value is {{ validation.$params.minValue.min }}
      </div>
      <div v-if="validation.maxValue === false">
        Maximum value is {{ validation.$params.maxValue.max }}
      </div>
      <div v-if="validation.wrongDate === false">
        Invalid date
      </div>
      <div v-if="validation.zipCode === false">
        Provide correct zip code
      </div>
      <div v-if="validation.bankAccount === false">
        Incorrect bank account number
      </div>
      <div v-if="validation.checked === false">
        This field must be checked
      </div>
      <div v-if="validation.phone === false">
        Invalid phone number
      </div>
      <div v-if="validation.url === false">
        Invalid url
      </div>
      <div v-if="validation.iban === false">
        Invalid Bank Account number
      </div>
      <div v-if="validation.hasCorrectAlready === false">
        Correct already exists
      </div>

      <slot>

      </slot>
    </div>
    <div class="errors-container" v-if="validation && validation?.$error && useCustomError">
      <slot></slot>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop, toNative } from 'vue-facing-decorator'

@Component({})
export class GenproxError extends Vue {
  @Prop({ default: false }) useInvalidProperty: boolean
  @Prop({ default: false }) useCustomError: boolean
  @Prop() validation: Record<string, unknown>

  /**
   * Use invalid property
   * @public
   */
  get useInvalid() {
    if (this.useInvalidProperty) {
      return this.validation?.$invalid
    } else {
      return false
    }
  }
}

export default toNative(GenproxError)
</script>

<docs>
```html
// Basic example:
<GenproxError :validation="v$?.fieldName" />

// Example with custom message:
<GenproxError :validation="v$?.fieldName" :useCustomError="true">
  Custom message goes here
</GenproxError>
```
</docs>